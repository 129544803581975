import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CurrencyViewer from "@/components/CurrencyViewer";
import MultiActionText from "@/components/MultiActionText";
import CardPopover from "@/pages/transaction-management/_components/CardPopover";
import { getCardImageURL } from "@/pages/transaction-management/TransactionManagement.helpers";
import { BarChartOutlined, CopyOutlined, EyeOutlined, InteractionOutlined, ThunderboltOutlined, UserOutlined } from "@ant-design/icons";
import { Badge, message, Space, Table, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
var Text = Typography.Text;
var PlayersTable = function (_a) {
    var seats = _a.seats, dealerIndex = _a.dealerIndex, currency = _a.currency;
    var t = useTranslation().t;
    var seatColumns = [
        {
            title: t("gameManagement.game.seat.number"),
            dataIndex: "index",
            key: "index",
            render: function (index) { return _jsx(Badge, { count: index === dealerIndex ? t("gameManagement.game.dealer") : index + 1, style: { backgroundColor: index === dealerIndex ? "#52c41a" : undefined } }); },
        },
        {
            title: t("gameManagement.game.seat.player"),
            dataIndex: "player",
            key: "player",
            render: function (player) {
                return (_jsx(MultiActionText, { title: player ? (_jsxs(Space, { children: [_jsx(UserOutlined, { style: { color: "#1890ff" } }), player.nickname || t("gameManagement.game.seat.empty")] })) : (_jsx(Text, { type: "secondary", children: t("gameManagement.game.seat.empty") })), actions: player
                        ? [
                            {
                                icon: CopyOutlined,
                                tooltip: t("root.copy_button"),
                                onClick: function () {
                                    navigator.clipboard.writeText(player.nickname).then(function () {
                                        message.success(t("root.copy_success"));
                                    });
                                },
                            },
                            {
                                icon: EyeOutlined,
                                tooltip: t("root.details"),
                                onClick: function () { return console.log("View button clicked"); },
                            },
                            {
                                icon: BarChartOutlined,
                                tooltip: t("root.show_transaction"),
                                onClick: function () { return console.log("View button clicked"); },
                            },
                        ]
                        : [] }));
            },
        },
        {
            title: t("gameManagement.game.seat.chips"),
            dataIndex: "chips",
            key: "chips",
            render: function (chips) { return _jsx(CurrencyViewer, { currency: currency, amount1: chips || 0 }); },
        },
        {
            title: t("gameManagement.game.seat.bet"),
            dataIndex: "betAmount",
            key: "betAmount",
            render: function (betAmount) { return _jsx(CurrencyViewer, { currency: currency, amount1: betAmount || 0 }); },
        },
        {
            title: t("gameManagement.game.seat.actions"),
            dataIndex: "actions",
            key: "actions",
            render: function (actions) {
                return (actions === null || actions === void 0 ? void 0 : actions.possible) ? (_jsx(Space, { wrap: true, children: actions.possible.map(function (possible) { return (_jsx(Tag, { color: "blue", children: t("gameManagement.columns.userAction.".concat(possible)) }, possible)); }) })) : ("-");
            },
        },
        {
            title: t("gameManagement.game.seat.lastAction"),
            dataIndex: "actions",
            key: "lastAction",
            render: function (actions) {
                var _a, _b;
                return ((_a = actions === null || actions === void 0 ? void 0 : actions.last) === null || _a === void 0 ? void 0 : _a.action) ? (_jsxs(Space, { children: [_jsx(InteractionOutlined, { style: { color: "purple" } }), _jsx(Tag, { color: "purple", children: t("gameManagement.columns.userAction.".concat(actions.last.action)) }), _jsx(CurrencyViewer, { currency: currency, amount1: (_b = actions === null || actions === void 0 ? void 0 : actions.last) === null || _b === void 0 ? void 0 : _b.amount })] })) : ("-");
            },
        },
        {
            title: t("gameManagement.game.seat.cards"),
            dataIndex: "cards",
            key: "cards",
            render: function (cards) {
                return (cards === null || cards === void 0 ? void 0 : cards.length) ? (_jsx(Space, { wrap: true, children: cards.map(function (card, index) { return (_jsx(CardPopover, { cardUrl: getCardImageURL(card) })); }) })) : ("-");
            },
        },
    ];
    return (_jsx(Table, { dataSource: seats, columns: seatColumns, pagination: false, bordered: true, title: function () { return (_jsxs(Space, { children: [_jsx(ThunderboltOutlined, { style: { color: "orange" } }), t("gameManagement.game.playerInfo")] })); } }));
};
export default PlayersTable;
