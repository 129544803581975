export const gameManagement = {
  gameManagement: {
    game: {
      title: "Oyun Detayı",
      details: "Oyun Detayları",
      name: "Oyun Adı",
      currency: "Para Birimi",
      createdBy: "Oluşturan",
      createdAt: "Oluşturulma Tarihi",
      updatedAt: "Güncellenme Tarihi",
      category: "Kategori",
      tenant: "Kiracı",
      tableId: "Tablo ID",
      roundId: "Tur ID",
      phase: "Aşama",
      pot: "Toplam Pot",
      bigBlind: "Büyük Blind",
      smallBlind: "Küçük Blind",
      lastAction: "Son Eylem",
      isBetHappened: "Bahis Yapıldı mı?",
      playerInfo: "Son Oyuncu Bilgileri",
      currentTurn: {
        player: "Şu Anki Oyuncu",
      },
      communityCards: "Topluluk Kartları",
      deck: "Deste",
      config: "Konfigürasyon",
      blinds: {
        small: "Küçük Blind",
        big: "Büyük Blind",
      },
      maxBuyIn: "Maksimum Giriş",
      totalSeats: "Toplam Koltuk Sayısı",
      actions: {
        title: "Eylemler",
        details: "Eylem Detayları",
        check: "Kontrol",
        fold: "Pas",
        raise: "Artır",
      },
      seat: {
        number: "Koltuk No",
        player: "Oyuncu",
        chips: "Fiş",
        bet: "Bahis Miktarı",
        cards: "Kartlar",
        actions: "Olası Eylemler",
        empty: "Boş",
        lastAction: "Son Eylem",
      },
      dealer: "Dağıtıcı",
    },
    common: {
      yes: "Evet",
      no: "Hayır",
    },
    edit: {
      title: "Oyunu Düzenle",
      id: "Kimlik",
      id_required: "Lütfen kimlik girin",
      table_id: "Masa Kimliği",
      players: "Oyuncular",
      last_bet_amount: "Son Bahis Miktarı",
      dealer_position: "Dağıtıcı Pozisyonu",
      current_turn: "Mevcut Tur",
      community_cards: "Topluluk Kartları",
      pot: "Pot",
      round: "Tur",
    },
    add: {
      title: "Oyun Ekle",
      table_id: "Masa Kimliği",
      table_id_placeholder: "Masa Kimliği Girin",
      players: "Oyuncular",
      players_placeholder: "Oyuncuları Girin",
      last_bet_amount: "Son Bahis Miktarı",
      last_bet_amount_placeholder: "Son Bahis Miktarını Girin",
      dealer_position: "Dağıtıcı Pozisyonu",
      dealer_position_placeholder: "Dağıtıcı Pozisyonunu Girin",
      current_turn: "Mevcut Tur",
      current_turn_placeholder: "Mevcut Turu Girin",
      community_cards: "Topluluk Kartları",
      community_cards_placeholder: "Topluluk Kartlarını Girin",
      pot: "Pot",
      pot_placeholder: "Pot Girin",
      round: "Tur",
      round_placeholder: "Tur Girin",
      success: "Oyun başarıyla eklendi",
      error: "Oyun eklenirken hata oluştu",
    },
    columns: {
      tenant: "Tenant",
      id: "Kimlik",
      table_id: "Masa Kimliği",
      lobby_id: "Lobi Kimliği",
      name: "Ad",
      type: "Tür",
      phase: "Aşama",
      currency: "Para Birimi",
      category: "Kategori",
      limitType: "Limit Türü",
      version: "Versiyon",
      skincode: "Cilt Kodu",
      organization: "Organizasyon",
      players: "Oyuncular",
      deck: "Deste",
      community_cards: "Topluluk Kartları",
      lastBetAmount: "Son Bahis Miktarı",
      pot: "Pot",
      serviceFeePercent: "Hizmet Ücreti Yüzdesi",
      totalSeats: "Toplam Koltuk Sayısı",
      currentPlayerIndex: "Mevcut Oyuncu İndeksi",
      currentTurn: "Mevcut Tur",
      timeLimitMs: "Süre Sınırı (ms)",
      minBuyIn: "Giriş",
      maxBuyIn: "Maksimum Giriş",
      ante: "Ante",
      timeoutDefaultAction: "Zaman Aşımı Varsayılan Eylemi",
      smallBlindIndex: "Küçük Kör Bahis İndeksi",
      bigBlindIndex: "Büyük Kör Bahis İndeksi",
      dealerPosition: "Dağıtıcı Pozisyonu",
      inProgress: "Devam Ediyor",
      isPasswordProtected: "Şifre Koruması Var mı",
      isActive: "Aktif mi",
      blinds: "Blinds",
      createdAt: "Oluşturulma Tarihi",
      updatedAt: "Güncellenme Tarihi",
      players: "Oyuncular",
      id: "Kimlik",
      username: "Kullanıcı Adı",
      type: "Tür",
      amount: "Miktar",
      combination: "Kombinasyon",
      activityDate: "Aktivite Tarihi",
      state: "Durum",
      userAction: {
        PLAYER_FOLD: "PAS",
        PLAYER_CALL: "ÇAĞRI",
        PLAYER_SIT: "OTUR",
        PLAYER_CHECK: "KONTROL",
        PLAYER_BET: "BAHİS",
        PLAYER_RAISE: "RAISE",
        PLAYER_ALLIN: "ALLIN",
        BIG_BLINDS_POSTED: "BÜYÜK KÖR BAHİS YAPILDI",
        SMALL_BLINDS_POSTED: "KÜÇÜK KÖR BAHİS YAPILDI",
      },
    },
    filters: {
      gameName_placeholder: "Oyun Adı",
      gameId_placeholder: "Oyun Kimliği",
      gameId: "Oyun Kimliği",
      gameName: "Oyun Adı",
    },
  },
};
