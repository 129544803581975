import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@atom/ui-kit";
import { Space, Tooltip } from "antd";
import { Container } from "./MultiActionText.style";
var MultiActionText = function (_a) {
    var title = _a.title, onClick = _a.onClick, _b = _a.actions, actions = _b === void 0 ? [] : _b;
    return (_jsxs(Container, { onClick: onClick, style: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [_jsx("div", { children: _jsx(Typography.Text, { children: title }) }), _jsx(Space, { children: actions.map(function (action, index) { return (_jsx(Tooltip, { title: action.tooltip, children: _jsx(action.icon, { style: { cursor: "pointer", fontSize: "1.2em" }, onClick: function (e) {
                            e.stopPropagation();
                            action.onClick();
                        } }) }, index)); }) })] }));
};
export default MultiActionText;
