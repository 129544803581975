var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TablesPostRequestTypeEnum } from "@/apis/table-management";
import PlayerSelect from "@/components/Selects/PlayerSelect";
import TableSelect from "@/components/Selects/TableSelect";
import { LocalStorageKeys } from "@/constants/localStorage";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "@atom/ui-kit";
import { DatePicker, Tooltip } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { TransactionTabKeys } from "./TransactionManagement.constants";
var RangePicker = DatePicker.RangePicker;
var TransactionManagementFilterSpace = function (_a) {
    var _b;
    var onSearch = _a.onSearch, selectedTab = _a.selectedTab;
    var form = Form.useForm()[0];
    var t = useTranslation().t;
    var _c = React.useState({
        gameType: "texasholdem",
        gameId: "",
    }), state = _c[0], setState = _c[1];
    var handleSearch = function (values) {
        if (onSearch)
            onSearch(values);
    };
    return (_jsx(Form, { form: form, layout: "vertical", onFinish: handleSearch, style: { width: "100%", justifyContent: "space-between" }, initialValues: {
            gameType: "texasholdem",
            gameId: "",
            dateRange: [dayjs().subtract(1, "months"), dayjs()],
        }, children: _jsxs(Row, { gutter: [10, 10], children: [[TransactionTabKeys.Table, TransactionTabKeys.Game].includes(selectedTab) && (_jsx(Col, { sm: 12, md: 6, children: _jsx(Form.Item, { label: t("transactionManagement.filters.gameType"), name: "gameType", rules: [{ required: state.gameId === "", message: t("transactionManagement.filters.gameType_required") }], children: _jsx(Select, { size: "small", variant: "borderless", placeholder: t("transactionManagement.filters.gameType_placeholder"), style: { width: "100%" }, onChange: function (value) { return setState(__assign(__assign({}, state), { gameType: value })); }, options: Object.values(TablesPostRequestTypeEnum).map(function (value) { return ({ label: value, value: value }); }) }) }) })), [TransactionTabKeys.Table, TransactionTabKeys.Game].includes(selectedTab) && (_jsx(Col, { sm: 12, md: 6, children: _jsx(Tooltip, { title: t("transactionManagement.filters.tableName_disabled"), children: _jsx(Form.Item, { label: t("transactionManagement.filters.tableName"), name: "tableName", rules: [{ required: state.gameId === "", message: t("transactionManagement.filters.tableName_required") }], children: _jsx(TableSelect, { tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming", gameType: state.gameType, disabled: ((_b = state.gameType) === null || _b === void 0 ? void 0 : _b.length) === 0, selector: "activeGame.id", placeholder: t("transactionManagement.filters.tableName_placeholder"), onChange: function (value) {
                                    setState(__assign(__assign({}, state), { gameId: value }));
                                    form.setFieldsValue({ gameId: value });
                                } }) }) }) })), selectedTab === TransactionTabKeys.Player && (_jsxs(_Fragment, { children: [_jsx(Col, { sm: 12, md: 8, children: _jsx(Form.Item, { label: t("transactionManagement.filters.username"), name: "userId", rules: [{ required: true, message: t("transactionManagement.filters.username_required") }], children: _jsx(PlayerSelect, { tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming", selector: "username", placeholder: t("transactionManagement.filters.username_placeholder") }) }) }), _jsx(Col, { sm: 12, md: 8, children: _jsx(Form.Item, { label: t("transactionManagement.filters.dateRange"), name: "dateRange", rules: [{ required: true, message: t("transactionManagement.filters.dateRange_required") }], children: _jsx(RangePicker, {}) }) })] })), selectedTab === TransactionTabKeys.Game && (_jsx(Col, { sm: 12, md: 8, children: _jsx(Form.Item, { label: t("transactionManagement.filters.gameId"), name: "gameId", rules: [{ required: true, message: t("transactionManagement.filters.gameId_required") }], children: _jsx(Input, { size: "small", value: state.gameId, onChange: function (e) { return setState(__assign(__assign({}, state), { gameId: e.target.value })); }, variant: "borderless", placeholder: state.gameId ? "" : t("transactionManagement.filters.gameId_placeholder") }) }) })), _jsx(Col, { sm: 2, children: _jsx(Form.Item, { children: _jsx(Button, { style: {
                                marginTop: "30px",
                            }, buttonSize: "small", icon: _jsx(SearchOutlined, {}), buttonType: "primary", htmlType: "submit" }) }) })] }) }));
};
export default TransactionManagementFilterSpace;
