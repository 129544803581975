var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableControllerApiFactory } from "@/apis/table-management";
import GoBack from "@/components/GoBack";
import { LocalStorageKeys } from "@/constants/localStorage";
import { useApi } from "@/hooks/useApi";
import { Card, message } from "@atom/ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GameConfigForm from "../add/_components/GameConfigForm";
import TableAddFormSteps from "../add/_components/TableAddFormSteps";
import TableFlagsForm from "../add/_components/TableFlagsForm";
import TableForm from "../add/_components/TableForm";
var EditTableManagement = function (_a) {
    var tableId = _a.tableId, data = _a.data;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _b = useState({
        step: 0,
        formValues: {
            tableFormValues: null,
            gameConfigFormValues: null,
            tableFlagsFormValues: null,
        },
    }), state = _b[0], setState = _b[1];
    var id = useParams().id;
    var location = useLocation();
    var tenant = new URLSearchParams(location.search).get("tenant") || localStorage.getItem(LocalStorageKeys.TENANT);
    var fetchTableManagementById = useApi({
        asyncFunction: TableControllerApiFactory().tablesByidTenantIdGet,
        successCallback: function (response) {
            setState(__assign(__assign({}, state), { formValues: {
                    tableFormValues: {
                        lobbyId: response.data.lobbyId,
                        tenant: response.data.tenant,
                        name: response.data.name,
                        currency: response.data.currency,
                        category: response.data.category,
                        isAutoCreateGame: response.data.isAutoCreateGame,
                        type: response.data.type,
                        allowedTenants: [],
                    },
                    gameConfigFormValues: response.data.gameConfig,
                    tableFlagsFormValues: null,
                } }));
        },
        errorCallback: function (error) {
            message.error("Error fetching data");
        },
    }).call;
    var _c = useApi({
        asyncFunction: TableControllerApiFactory().tablesByidTenantIdPut,
        successCallback: function (response) {
            message.success("TableManagement edited successfully");
            navigate("/table-management");
        },
        errorCallback: function (error) {
            message.error("Error editing TableManagement");
        },
    }), callEditTableManagement = _c.call, loading = _c.loading;
    useEffect(function () {
        if (tenant && id && !tableId) {
            fetchTableManagementById({ tenant: tenant, id: id });
        }
        if (tableId && data) {
            setState(__assign(__assign({}, state), { formValues: {
                    tableFormValues: {
                        lobbyId: data.lobbyId,
                        tenant: data.tenant,
                        name: data.name,
                        currency: data.currency,
                        category: data.category,
                        isAutoCreateGame: data.isAutoCreateGame,
                        type: data.type,
                        allowedTenants: [],
                    },
                    gameConfigFormValues: data.gameConfig,
                    tableFlagsFormValues: null,
                } }));
        }
    }, [tenant, id, tableId]);
    console.log(data);
    var handleFinish = function (values) {
        var _a;
        callEditTableManagement({
            tenant: tenant,
            id: id,
            tablesByidTenantIdPutRequest: __assign(__assign({}, state.formValues.tableFormValues), { isAutoCreateGame: values.isAutoCreateGame, gameConfig: __assign(__assign({}, (_a = state === null || state === void 0 ? void 0 : state.formValues) === null || _a === void 0 ? void 0 : _a.gameConfigFormValues), values) }),
        });
        setState(__assign(__assign({}, state), { formValues: __assign(__assign({}, state.formValues), { tableFlagsFormValues: values }) }));
    };
    var handleTableFormFinish = function (values) {
        setState({
            step: 1,
            formValues: {
                tableFormValues: values,
                gameConfigFormValues: {},
                tableFlagsFormValues: {},
            },
        });
    };
    var handleGameConfigFormFinish = function (values) {
        var _a;
        setState({
            step: 2,
            formValues: __assign(__assign({}, state.formValues), { gameConfigFormValues: values, tableFormValues: (_a = state.formValues) === null || _a === void 0 ? void 0 : _a.tableFormValues }),
        });
    };
    var handleChangeStep = function (current) {
        setState(__assign(__assign({}, state), { step: current }));
    };
    return (_jsxs(Card, { style: { padding: "20px 0" }, children: [_jsx(GoBack, { title: t("tableManagement.edit.title") }), _jsx(TableAddFormSteps, { t: t, current: state.step, onChange: handleChangeStep }), state.step === 0 && _jsx(TableForm, { t: t, onFinish: handleTableFormFinish, formValues: state.formValues.tableFormValues }), state.step === 1 && _jsx(GameConfigForm, { t: t, onFinish: handleGameConfigFormFinish, formValues: state.formValues.gameConfigFormValues, onGoBack: function () { return handleChangeStep(0); } }), state.step === 2 && _jsx(TableFlagsForm, { t: t, onFinish: handleFinish, loading: loading, formValues: state.formValues.tableFlagsFormValues, onGoBack: function () { return handleChangeStep(1); } })] }));
};
export default EditTableManagement;
