var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DefaultApiFactory } from "@/apis/transaction-management";
import { TransactionTabKeys } from "./TransactionManagement.constants";
var formatCardName = function (card) {
    if (card.startsWith("10")) {
        return card.replace("10", "0");
    }
    return card;
};
export var getCardImageURL = function (card) {
    var formattedCard = formatCardName(card);
    return "https://deckofcardsapi.com/static/img/".concat(formattedCard, ".png");
};
export var fetchTransactions = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
    var _b;
    var selectedTab = _a.selectedTab, params = __rest(_a, ["selectedTab"]);
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                console.log(selectedTab, params);
                _b = selectedTab;
                switch (_b) {
                    case TransactionTabKeys.Table: return [3 /*break*/, 1];
                    case TransactionTabKeys.Game: return [3 /*break*/, 3];
                    case TransactionTabKeys.Player: return [3 /*break*/, 5];
                    case TransactionTabKeys.Tenant: return [3 /*break*/, 7];
                }
                return [3 /*break*/, 9];
            case 1: return [4 /*yield*/, DefaultApiFactory().getTransactionsByTableId(params)];
            case 2: return [2 /*return*/, _c.sent()];
            case 3: return [4 /*yield*/, DefaultApiFactory().getTransactionsByGameId(params)];
            case 4: return [2 /*return*/, _c.sent()];
            case 5: return [4 /*yield*/, DefaultApiFactory().getUserTransactionsByTimestamp(params)];
            case 6: return [2 /*return*/, _c.sent()];
            case 7: return [4 /*yield*/, DefaultApiFactory().getAllTransactions(params)];
            case 8: return [2 /*return*/, _c.sent()];
            case 9: return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}); };
