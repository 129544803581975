var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { theme } from "@atom/ui-kit";
import { Collapse } from "antd";
import ArrowChevronIcon from "../Icons/ArrowChevronIcon";
import { Container } from "./AtomAccordion.style";
var text = "\n  A dog is a type of domesticated animal.\n  Known for its loyalty and faithfulness,\n  it can be found as a welcome guest in many households across the world.\n";
var AtomAccordion = function (_a) {
    var props = __rest(_a, []);
    return (_jsx(Container, { theme: theme, children: _jsx(Collapse, __assign({ bordered: false, expandIcon: function (_a) {
                var isActive = _a.isActive;
                return _jsx(ArrowChevronIcon, { rotate: isActive ? 90 : 270 });
            }, style: { background: theme.colors.GrayscaleBackground, boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.02)" }, expandIconPosition: "right", ghost: true }, props)) }));
};
export default AtomAccordion;
