import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CurrencyViewer from "@/components/CurrencyViewer";
import { CalendarOutlined, IdcardOutlined, TagOutlined, UserOutlined } from "@ant-design/icons";
import { Collapse, List, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
var Text = Typography.Text;
var Panel = Collapse.Panel;
var GameDetails = function (_a) {
    var pokerGameData = _a.pokerGameData;
    var t = useTranslation().t;
    var _b = pokerGameData || {}, state = _b.state, name = _b.name, currency = _b.currency, createdAt = _b.createdAt, updatedAt = _b.updatedAt, createdBy = _b.createdBy, tableId = _b.tableId, roundId = _b.roundId, category = _b.category, tenant = _b.tenant;
    var detailsData = [
        {
            label: t("gameManagement.game.name"),
            value: _jsx(Tag, { color: "blue", children: name }),
        },
        {
            label: t("gameManagement.game.tableId"),
            value: (_jsxs(Tag, { color: "green", children: [_jsx(IdcardOutlined, {}), " ", tableId] })),
        },
        {
            label: t("gameManagement.game.roundId"),
            value: _jsx(Tag, { color: "purple", children: roundId }),
        },
        {
            label: t("gameManagement.game.currency"),
            value: _jsx(CurrencyViewer, { amount1: (state === null || state === void 0 ? void 0 : state.pot) || 0, currency: currency || "USD" }),
        },
        {
            label: t("gameManagement.game.createdBy"),
            value: (_jsxs(Tag, { color: "volcano", children: [_jsx(UserOutlined, {}), " ", createdBy] })),
        },
        {
            label: t("gameManagement.game.createdAt"),
            value: (_jsxs(Tag, { color: "magenta", children: [_jsx(CalendarOutlined, {}), " ", new Date(createdAt * 1000).toLocaleString()] })),
        },
        {
            label: t("gameManagement.game.updatedAt"),
            value: (_jsxs(Tag, { color: "gold", children: [_jsx(CalendarOutlined, {}), " ", new Date(updatedAt * 1000).toLocaleString()] })),
        },
        {
            label: t("gameManagement.game.category"),
            value: (_jsxs(Tag, { color: "cyan", children: [_jsx(TagOutlined, {}), " ", category] })),
        },
        {
            label: t("gameManagement.game.tenant"),
            value: _jsx(Tag, { color: "lime", children: tenant }),
        },
    ];
    return (_jsx(Collapse, { children: _jsx(Panel, { header: t("gameManagement.game.details"), children: _jsx(List, { dataSource: detailsData, renderItem: function (item) { return (_jsxs(List.Item, { children: [_jsxs(Text, { strong: true, children: [item.label, ":"] }), " ", item.value] })); } }) }, "1") }));
};
export default GameDetails;
