export const gameManagement = {
  gameManagement: {
    game: {
      title: "Game Detail",
      details: "Game Details",
      name: "Game Name",
      currency: "Currency",
      createdBy: "Created By",
      createdAt: "Created At",
      updatedAt: "Updated At",
      category: "Category",
      tenant: "Tenant",
      tableId: "Table ID",
      roundId: "Round ID",
      phase: "Phase",
      pot: "Total Pot",
      bigBlind: "Big Blind",
      smallBlind: "Small Blind",
      lastAction: "Last Action",
      isBetHappened: "Has Bet Happened?",
      playerInfo: "Last Players Info",
      currentTurn: {
        player: "Current Player",
      },
      communityCards: "Community Cards",
      deck: "Deck",
      config: "Configuration",
      blinds: {
        small: "Small Blind",
        big: "Big Blind",
      },
      maxBuyIn: "Max Buy-In",
      totalSeats: "Total Seats",
      actions: {
        title: "Actions",
        details: "Action Details",
        check: "Check",
        fold: "Fold",
        raise: "Raise",
      },
      seat: {
        number: "Seat No",
        player: "Player",
        chips: "Chips",
        bet: "Bet Amount",
        cards: "Cards",
        actions: "Possible Actions",
        lastAction: "Last Action",
        empty: "Empty",
      },
      dealer: "Dealer",
    },
    common: {
      yes: "Yes",
      no: "No",
    },

    edit: {
      title: "Edit Game",
      id: "ID",
      id_required: "Please enter ID",
      table_id: "Table ID",
      players: "Players",
      last_bet_amount: "Last Bet Amount",
      dealer_position: "Dealer Position",
      current_turn: "Current Turn",
      community_cards: "Community Cards",
      pot: "Pot",
      round: "Round",
    },
    add: {
      title: "Add Game",
      table_id: "Table ID",
      table_id_placeholder: "Enter Table ID",
      players: "Players",
      players_placeholder: "Enter Players",
      last_bet_amount: "Last Bet Amount",
      last_bet_amount_placeholder: "Enter Last Bet Amount",
      dealer_position: "Dealer Position",
      dealer_position_placeholder: "Enter Dealer Position",
      current_turn: "Current Turn",
      current_turn_placeholder: "Enter Current Turn",
      community_cards: "Community Cards",
      community_cards_placeholder: "Enter Community Cards",
      pot: "Pot",
      pot_placeholder: "Enter Pot",
      round: "Round",
      round_placeholder: "Enter Round",
      success: "GameManagement added successfully",
      error: "Error adding GameManagement",
    },
    columns: {
      createdAt: "Round Created Date",
      updatedAt: "Round Updated Date",
      id: "Round ID",
      table_id: "Table ID",
      lobby_id: "Lobby ID",
      name: "Name",
      state: "Round State",
      pot: "Round Pot",
      winner: "Round Winner",
      userAction: {
        PLAYER_FOLD: "FOLD",
        PLAYER_CALL: "CALL",
        PLAYER_SIT: "SIT",
        PLAYER_CHECK: "CHECK",
        PLAYER_BET: "BET",
        PLAYER_RAISE: "RAISE",
        PLAYER_ALLIN: "ALLIN",
        BIG_BLINDS_POSTED: "BIG BLINDS POSTED",
        SMALL_BLINDS_POSTED: "SMALL BLINDS POSTED",
      },
    },
    filters: {
      gameName_placeholder: "Game Name",
      gameId_placeholder: "Game ID",
      gameId: "Game ID",
      gameName: "Game Name",
    },
  },
};
