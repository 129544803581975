export const tableManagement = {
  tableManagement: {
    edit: {
      title: "Masayı Düzenle",
      add_table: "Masa Ekle",
      tenant: "Tenant",
      tenant_required: "Lütfen bir tenant girin",
      lobby_id: "Lobi Kimliği",
      lobby_id_required: "Lütfen bir lobi kimliği girin",
      allowedTenants: "İzin Verilen Tenantlar",
      allowedTenants_required: "Lütfen izin verilen tenantları girin",
      name: "Ad",
      name_required: "Lütfen bir ad girin",
      skincode: "Skin Kodu",
      skincode_required: "Lütfen bir skin kodu girin",
      category: "Kategori",
      category_required: "Lütfen bir kategori seçin",
      type: "Tür",
      type_required: "Lütfen bir tür seçin",
      currency: "Para Birimi",
      currency_required: "Lütfen para birimi girin",
      limitType: "Limit Türü",
      limitType_required: "Lütfen bir limit türü seçin",
      totalSeats: "Toplam Koltuk",
      totalSeats_required: "Lütfen toplam koltuk sayısını girin",
      totalSeats_max_required: "Toplam koltuk sayısı mevcut 9 koltuktan küçük veya eşit olmalıdır",
      minPlayers: "Minimum Oyuncu",
      minPlayers_required: "Lütfen minimum oyuncu sayısını girin",
      currentAllocatedSeats: "Atanmış Koltuklar",
      currentAllocatedSeats_required: "Lütfen atanmış koltuk sayısını girin",
      minBuyIn: "Minimum Giriş",
      minBuyIn_required: "Lütfen minimum giriş miktarını girin",
      maxBuyIn: "Maksimum Giriş",
      maxBuyIn_required: "Lütfen maksimum giriş miktarını girin",
      blinds: "Blinds",
      blinds_small_required: "Küçük blind",
      blinds_big_required: "Büyük blind",
      blind_placeholder: "Blinds Girin",
      ante: "Ante",
      timeLimitMs: "Süre Sınırı (ms)",
      timeLimitMs_required: "Lütfen süre sınırını girin",
      timeoutDefaultAction: "Zaman Aşımı Varsayılan Eylemi",
      timeoutDefaultAction_required: "Lütfen bir varsayılan eylem seçin",
      serviceFeePercent: "Hizmet Ücreti (%)",
      serviceFeePercent_required: "Lütfen hizmet ücreti yüzdesini girin",
      autoRetable: "Otomatik Yenileme",
      autoRetable_required: "Lütfen otomatik yenilemeyi girin",
      highlighted: "Vurgulandı",
      gameInProgress: "Oyun Devam Ediyor",
      waitingPlayersCount: "Bekleyen Oyuncu Sayısı",
      canJoinWaitingList: "Bekleme Listesine Katılabilir",
      canLeaveWaitingList: "Bekleme Listesinden Ayrılabilir",
      isPasswordProtected: "Şifre Koruması Var mı",
      submit_button: "Ekle",
      success: "Masa başarıyla eklendi",
      error: "Masa eklenirken hata oluştu",
    },
    add: {
      steps: {
        step1: "Masa Yapılandırması",
        step2: "Oyun Yapılandırması",
        step3: "Masa Anahtarları",
      },
      title: "Masa Ekle",

      add_table: "Masa Ekle",
      tenant: "Tenant",
      tenant_placeholder: "Tenant Girin",
      tenant_required: "Lütfen bir tenant girin",
      lobby_id: "Lobi Kimliği",
      lobby_id_placeholder: "Lobi Kimliği Girin",
      lobby_id_required: "Lütfen bir lobi kimliği girin",
      allowedTenants: "İzin Verilen Tenantlar",
      allowedTenants_placeholder: "İzin verilen tenantları girin",
      allowedTenants_required: "Lütfen izin verilen tenantları girin",
      name: "Ad",
      name_placeholder: "Ad Girin",
      name_required: "Lütfen bir ad girin",
      skincode: "Skin Kodu",
      skincode_placeholder: "Skin kodunu girin",
      skincode_required: "Lütfen bir skin kodu girin",
      category: "Kategori",
      category_placeholder: "Kategori Seçin",
      category_required: "Lütfen bir kategori seçin",
      type: "Tür",
      type_placeholder: "Tür Seçin",
      type_required: "Lütfen bir tür seçin",
      currency: "Para Birimi",
      currency_placeholder: "Para birimi girin",
      currency_required: "Lütfen para birimi girin",
      limitType: "Limit Türü",
      limitType_placeholder: "Limit türünü seçin",
      limitType_required: "Lütfen bir limit türü seçin",
      totalSeats: "Toplam Koltuk",
      totalSeats_placeholder: "Toplam koltuk sayısını girin",
      totalSeats_required: "Lütfen toplam koltuk sayısını girin",
      totalSeats_max_required: "Toplam koltuk sayısı mevcut 9 koltuktan küçük veya eşit olmalıdır",
      minPlayers: "Minimum Oyuncu",
      minPlayers_placeholder: "Minimum oyuncu sayısını girin",
      minPlayers_required: "Lütfen minimum oyuncu sayısını girin",
      currentAllocatedSeats: "Atanmış Koltuklar",
      currentAllocatedSeats_placeholder: "Atanmış koltuk sayısını girin",
      currentAllocatedSeats_required: "Lütfen atanmış koltuk sayısını girin",
      minBuyIn: "Minimum Giriş",
      minBuyIn_placeholder: "Minimum giriş miktarını girin",
      minBuyIn_required: "Lütfen minimum giriş miktarını girin",
      maxBuyIn: "Maksimum Giriş",
      maxBuyIn_placeholder: "Maksimum giriş miktarını girin",
      maxBuyIn_required: "Lütfen maksimum giriş miktarını girin",
      blinds: "Blinds",
      blinds_small_required: "Küçük blind",
      blinds_big_required: "Büyük blind",
      ante: "Ante",
      ante_placeholder: "Ante girin",
      timeLimitMs: "Süre Sınırı (ms)",
      timeLimitMs_placeholder: "Süre sınırını ms cinsinden girin",
      timeLimitMs_required: "Lütfen süre sınırını girin",
      timeoutDefaultAction: "Zaman Aşımı Varsayılan Eylemi",
      timeoutDefaultAction_placeholder: "Varsayılan eylemi seçin",
      timeoutDefaultAction_required: "Lütfen bir varsayılan eylem seçin",
      serviceFeePercent: "Hizmet Ücreti (%)",
      serviceFeePercent_placeholder: "Hizmet ücreti yüzdesini girin",
      serviceFeePercent_required: "Lütfen hizmet ücreti yüzdesini girin",
      autoRetable: "Otomatik Yenileme",
      autoRetable_required: "Lütfen otomatik yenilemeyi girin",
      highlighted: "Vurgulandı",
      gameInProgress: "Oyun Devam Ediyor",
      waitingPlayersCount: "Bekleyen Oyuncu Sayısı",
      waitingPlayersCount_placeholder: "Bekleyen oyuncu sayısını girin",
      canJoinWaitingList: "Bekleme Listesine Katılabilir",
      canLeaveWaitingList: "Bekleme Listesinden Ayrılabilir",
      isPasswordProtected: "Şifre Koruması Var mı",
      submit_button: "Ekle",
      success: "Masa başarıyla eklendi",
      error: "Masa eklenirken hata oluştu",
    },
    columns: {
      name: "Ad",
      id: "Kimlik",
      currency: "Para Birimi",
      totalSeats: "Toplam Koltuk",
      waitingPlayersCount: "Bekleyen Oyuncu Sayısı",
      timeoutDefaultAction: "Zaman Aşımı Varsayılan Eylemi",
      minPlayers: "Minimum Oyuncu",
      createdBy: "Oluşturan",
      isPasswordProtected: "Şifre Korumalı",
      highlighted: "Vurgulandı",
      autoRetable: "Otomatik Yenileme",
      currentAllocatedSeats: "Atanmış Koltuklar",
      skincode: "Skin Kodu",
      serviceFeePercent: "Hizmet Ücreti (%)",
      createdAt: "Oluşturulma Tarihi",
      timeLimitMs: "Süre Sınırı (ms)",
      tenant: "Tenant",
      pot: "Pot",
      limitType: "Limit Türü",
      buyInRange: "Giriş Aralığı",
      blinds: "Blinds",
      type: "Oyun Türü",
      category: "Kategori",
      action: "Eylemler",
      players: "Oyuncular",
      lobby: "Lobi",
    },
    detail: {
      tableInfo: "Masa Bilgileri",
      name: "Masa Adı",
      type: "Masa Türü",
      currency: "Para Birimi",
      blinds: {
        small: "Küçük Blinds",
        big: "Büyük Blinds",
      },
      buyInRange: "Buy-In Aralığı",
      totalSeats: "Toplam Koltuk Sayısı",
      state: "Oyun Durumu",
      phase: "Oyun Aşaması",
      pot: "Pot Miktarı",
      communityCards: "Topluluk Kartları",
      currentTurn: "Şu Anki Tur",
      playerSeats: "Oyuncu Koltukları",
      noCards: "Kart Yok",
      noPlayer: "Oyuncu Yok",
      seat: {
        number: "Koltuk Numarası",
        player: "Oyuncu",
        empty: "Boş",
      },
      activeGame: "Aktif Oyun",
      activeGameDescription: "Bu masada şu an aktif bir oyun bulunmaktadır.",
      additionalDetails: "Ek Detaylar",
      totalPot: "Toplam Pot",
      activePlayers: "Aktif Oyuncular",
      lastWinners: "Son Kazananlar",
      noWinners: "Kazanan yok",
      gameSpeed: "Oyun Hızı",
      speedFast: "Hızlı",
      speedNormal: "Orta",
      speedSlow: "Yavaş",
    },
  },
};
