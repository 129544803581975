var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n\n  .ant-collapse {\n    background: transparent !important;\n    .ant-collapse-item {\n      background-color: ", ";\n      margin-bottom: 20px;\n      border-radius: 20px;\n      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.02);\n      .ant-collapse-header {\n        padding: 20px 30px;\n        .ant-collapse-header-text {\n          color: ", ";\n          font-size: 15px;\n          font-style: normal;\n          font-weight: 700;\n          line-height: 20px;\n          letter-spacing: 0.35px;\n        }\n      }\n    }\n  }\n"], ["\n  cursor: pointer;\n\n  .ant-collapse {\n    background: transparent !important;\n    .ant-collapse-item {\n      background-color: ", ";\n      margin-bottom: 20px;\n      border-radius: 20px;\n      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.02);\n      .ant-collapse-header {\n        padding: 20px 30px;\n        .ant-collapse-header-text {\n          color: ", ";\n          font-size: 15px;\n          font-style: normal;\n          font-weight: 700;\n          line-height: 20px;\n          letter-spacing: 0.35px;\n        }\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.GrayscaleBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.PrimaryDefault;
});
var templateObject_1;
