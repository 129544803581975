var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import TransactionManagement from "@/pages/transaction-management";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { Container } from "./TransactionDetailDrawer.style";
var TransactionDetailDrawer = function (_a) {
    var open = _a.open, onCLose = _a.onCLose, name = _a.name, props = __rest(_a, ["open", "onCLose", "name"]);
    var t = useTranslation().t;
    return (_jsx(Drawer, { width: 1500, open: open, onClose: onCLose, closeIcon: _jsx(ArrowLeftOutlined, {}), title: name + " " + t("root.transactions"), children: _jsx(Container, { children: _jsx(TransactionManagement, __assign({ isReusable: true }, props)) }) }));
};
export default TransactionDetailDrawer;
