import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AtomAccordion from "@/components/AtomAccordion";
import AtomList from "@/components/AtomList";
import OvalTable from "@/components/OvalTable";
import { deleteTenant } from "@/utils/string";
import { Col, Row } from "antd";
import dayjs from "dayjs";
var TableDetail = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14;
    var recordData = _a.recordData, t = _a.t;
    return (_jsxs(Row, { gutter: 20, children: [_jsx(Col, { xs: 24, sm: 14, children: _jsx(AtomAccordion, { defaultActiveKey: ["tableInfo"], items: [
                        {
                            label: t("tableManagement.moreInfoDrawer.detail.tableInfo"),
                            key: "tableInfo",
                            children: (_jsx(AtomList, { columnCount: 3, rowCount: 2, items: [
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.name"),
                                        value: recordData === null || recordData === void 0 ? void 0 : recordData.name,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.type"),
                                        value: recordData === null || recordData === void 0 ? void 0 : recordData.type,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.lobbyId"),
                                        value: recordData === null || recordData === void 0 ? void 0 : recordData.lobbyId,
                                        type: "id",
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.currency"),
                                        value: recordData === null || recordData === void 0 ? void 0 : recordData.currency,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.blinds"),
                                        value: ((_c = (_b = recordData.gameConfig) === null || _b === void 0 ? void 0 : _b.blinds) === null || _c === void 0 ? void 0 : _c.small) + " - " + ((_e = (_d = recordData === null || recordData === void 0 ? void 0 : recordData.gameConfig) === null || _d === void 0 ? void 0 : _d.blinds) === null || _e === void 0 ? void 0 : _e.big) + " " + (recordData === null || recordData === void 0 ? void 0 : recordData.currency),
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.buyInRange"),
                                        value: ((_f = recordData.gameConfig) === null || _f === void 0 ? void 0 : _f.minBuyIn) + " - " + ((_g = recordData === null || recordData === void 0 ? void 0 : recordData.gameConfig) === null || _g === void 0 ? void 0 : _g.maxBuyIn),
                                    },
                                ] })),
                        },
                        {
                            label: t("tableManagement.moreInfoDrawer.detail.additionalDetails"),
                            key: "additionalDetails",
                            children: (_jsx(AtomList, { columnCount: 4, rowCount: 2, items: [
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.createdAt"),
                                        value: dayjs((recordData === null || recordData === void 0 ? void 0 : recordData.createdAt) * 1000).format("YYYY-MM-DD HH:mm:ss"),
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.createdBy"),
                                        value: deleteTenant(recordData === null || recordData === void 0 ? void 0 : recordData.createdBy),
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.updatedAt"),
                                        value: dayjs((recordData === null || recordData === void 0 ? void 0 : recordData.updatedAt) * 1000).format("YYYY-MM-DD HH:mm:ss"),
                                        type: "id",
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.id"),
                                        value: recordData === null || recordData === void 0 ? void 0 : recordData.id,
                                        type: "id",
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.isAutoCreateGame"),
                                        value: (recordData === null || recordData === void 0 ? void 0 : recordData.isAutoCreateGame) ? t("root.yes") : t("root.no"),
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.highlighted"),
                                        value: (recordData === null || recordData === void 0 ? void 0 : recordData.highlighted) ? t("root.yes") : t("root.no"),
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.version"),
                                        value: recordData.version,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.serviceFeePercent"),
                                        value: (_h = recordData === null || recordData === void 0 ? void 0 : recordData.gameConfig) === null || _h === void 0 ? void 0 : _h.serviceFeePercent,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.timeoutDefaultAction"),
                                        value: (_j = recordData === null || recordData === void 0 ? void 0 : recordData.gameConfig) === null || _j === void 0 ? void 0 : _j.timeoutDefaultAction,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.skinCode"),
                                        value: (_k = recordData === null || recordData === void 0 ? void 0 : recordData.gameConfig) === null || _k === void 0 ? void 0 : _k.skinCode,
                                    },
                                ] })),
                        },
                        {
                            label: t("tableManagement.moreInfoDrawer.detail.gameState"),
                            key: "gameState",
                            children: (_jsx(AtomList, { items: [
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.dealerIndex"),
                                        value: (_m = (_l = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _l === void 0 ? void 0 : _l.state) === null || _m === void 0 ? void 0 : _m.dealerIndex,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.dealerPosition"),
                                        value: (_p = (_o = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _o === void 0 ? void 0 : _o.state) === null || _p === void 0 ? void 0 : _p.dealerPosition,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.inProgress"),
                                        value: ((_r = (_q = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _q === void 0 ? void 0 : _q.state) === null || _r === void 0 ? void 0 : _r.inProgress) ? t("root.yes") : t("root.no"),
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.isBetHappened"),
                                        value: ((_t = (_s = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _s === void 0 ? void 0 : _s.state) === null || _t === void 0 ? void 0 : _t.isBetHappened) ? t("root.yes") : t("root.no"),
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.isEnded"),
                                        value: ((_v = (_u = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _u === void 0 ? void 0 : _u.state) === null || _v === void 0 ? void 0 : _v.isEnded) ? t("root.yes") : t("root.no"),
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.smallBlindIndex"),
                                        value: (_x = (_w = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _w === void 0 ? void 0 : _w.state) === null || _x === void 0 ? void 0 : _x.smallBlindIndex,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.tipPot"),
                                        value: (_z = (_y = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _y === void 0 ? void 0 : _y.state) === null || _z === void 0 ? void 0 : _z.tipPot,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.sidePot"),
                                        value: (_1 = (_0 = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _0 === void 0 ? void 0 : _0.state) === null || _1 === void 0 ? void 0 : _1.sidePot,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.waitingPlayersCount"),
                                        value: (_3 = (_2 = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _2 === void 0 ? void 0 : _2.state) === null || _3 === void 0 ? void 0 : _3.waitingPlayersCount,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.pot"),
                                        value: (_5 = (_4 = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _4 === void 0 ? void 0 : _4.state) === null || _5 === void 0 ? void 0 : _5.pot,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.phase"),
                                        value: (_7 = (_6 = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _6 === void 0 ? void 0 : _6.state) === null || _7 === void 0 ? void 0 : _7.phase,
                                    },
                                    {
                                        label: t("tableManagement.moreInfoDrawer.detail.lastAction"),
                                        value: (_10 = (_9 = (_8 = recordData === null || recordData === void 0 ? void 0 : recordData.activeGame) === null || _8 === void 0 ? void 0 : _8.state) === null || _9 === void 0 ? void 0 : _9.lastAction) === null || _10 === void 0 ? void 0 : _10.action,
                                    },
                                ] })),
                        },
                    ] }) }), _jsx(Col, { xs: 24, sm: 10, children: _jsx(AtomAccordion, { defaultActiveKey: ["tableInfo"], items: [
                        {
                            label: t("tableManagement.moreInfoDrawer.detail.playerSeats"),
                            key: "tableInfo",
                            children: (_jsx("div", { style: {
                                    width: "100%",
                                }, children: _jsx(OvalTable, { seats: (_11 = (recordData === null || recordData === void 0 ? void 0 : recordData.activeGame).seats) === null || _11 === void 0 ? void 0 : _11.map(function (seat, index) {
                                        var _a, _b;
                                        return ({
                                            seatNumber: index + 1,
                                            isAvailable: seat.status === "EMPTY",
                                            username: ((_a = seat.player) === null || _a === void 0 ? void 0 : _a.username) || ((_b = seat.player) === null || _b === void 0 ? void 0 : _b.nickname),
                                        });
                                    }), activePlayers: (_13 = (_12 = (recordData === null || recordData === void 0 ? void 0 : recordData.activeGame).seats) === null || _12 === void 0 ? void 0 : _12.filter(function (seat) { return seat.status !== "EMPTY"; })) === null || _13 === void 0 ? void 0 : _13.length, totalSeats: (_14 = (recordData === null || recordData === void 0 ? void 0 : recordData.activeGame).config) === null || _14 === void 0 ? void 0 : _14.totalSeats }) })),
                        },
                    ] }) })] }));
};
export default TableDetail;
