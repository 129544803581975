var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var TransactionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .table-winner-row {\n    background-color: ", ";\n  }\n"], ["\n  .table-winner-row {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.Red100;
});
var templateObject_1;
