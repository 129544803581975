import { jsx as _jsx } from "react/jsx-runtime";
import { AppleOutlined, CarOutlined, CrownOutlined, HomeOutlined, RubyOutlined, ShakeOutlined, UserOutlined } from "@ant-design/icons";
import { UserRoles } from "@/constants/role";
export var menuConfig = [
    {
        key: "dashboard",
        icon: _jsx(HomeOutlined, {}),
        path: "/",
        visibleFor: [UserRoles.Godmin, UserRoles.Lobadmin, UserRoles.Opadmin],
    },
    {
        key: "table-management",
        icon: _jsx(AppleOutlined, {}),
        path: "/table-management",
        visibleFor: [UserRoles.Godmin, UserRoles.Lobadmin, UserRoles.Opadmin],
    },
    {
        key: "game-management",
        icon: _jsx(RubyOutlined, {}),
        path: "/game-management",
        visibleFor: [UserRoles.Godmin, UserRoles.Lobadmin, UserRoles.Opadmin],
    },
    {
        key: "transaction-management",
        icon: _jsx(RubyOutlined, {}),
        path: "/transaction-management",
        visibleFor: [UserRoles.Godmin, UserRoles.Lobadmin, UserRoles.Opadmin],
    },
    {
        key: "lobby-management",
        icon: _jsx(CarOutlined, {}),
        path: "/lobby-management",
        visibleFor: [UserRoles.Godmin, UserRoles.Lobadmin, UserRoles.Opadmin],
    },
    {
        key: "user-management",
        icon: _jsx(UserOutlined, {}),
        path: "/user-management",
        visibleFor: [UserRoles.Godmin],
    },
    {
        key: "tenant-management",
        icon: _jsx(CrownOutlined, {}),
        path: "/tenant-management",
        visibleFor: [UserRoles.Godmin],
    },
    {
        key: "player-management",
        icon: _jsx(ShakeOutlined, {}),
        path: "/player-management",
        visibleFor: [UserRoles.Godmin],
    },
];
