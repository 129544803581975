var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PlayerControllerApiFactory } from "@/apis/player-management";
import useApi from "@/hooks/useApi";
import { Select } from "@atom/ui-kit";
import React, { useEffect } from "react";
var PlayerSelect = function (props) {
    var _a;
    var _b = React.useState(""), searchValue = _b[0], setSearchValue = _b[1];
    var _c = useApi({
        asyncFunction: PlayerControllerApiFactory().playersGet,
    }), call = _c.call, data = _c.data, loading = _c.loading;
    var _d = useApi({
        asyncFunction: PlayerControllerApiFactory().playersBynameUsernameGet,
    }), callbyId = _d.call, dataById = _d.data, loadingById = _d.loading;
    useEffect(function () {
        call({
            limit: 100,
        });
    }, []);
    useEffect(function () {
        if (searchValue.length > 0) {
            callbyId({
                username: searchValue,
            });
        }
    }, [searchValue]);
    var prepareData = function () {
        if (searchValue.length > 0) {
            return dataById ? [dataById.data.player] : undefined;
        }
        return data === null || data === void 0 ? void 0 : data.data.players;
    };
    return (_jsx(Select, __assign({ style: { width: "100%" }, loading: loading || loadingById, showSearch: true, onSearch: setSearchValue }, props, { children: (_a = prepareData()) === null || _a === void 0 ? void 0 : _a.map(function (item) { return (_jsx(Select.Option, { value: props.selector ? item === null || item === void 0 ? void 0 : item[props.selector] : item === null || item === void 0 ? void 0 : item.id, children: item === null || item === void 0 ? void 0 : item.username }, item === null || item === void 0 ? void 0 : item.user_id)); }) })));
};
export default PlayerSelect;
