export const transactionManagement = {
  transactionManagement: {
    columns: {
      username: "Kullanıcı Adı",
      tableStatus: "Masa Durumu",
      playerCards: "Oyuncu Kartları",
      otherPlayerCards: "Diğer Oyuncu Kartları",
      communityCards: "Topluluk Kartları",
      type: "Aksiyon Türü",
      amount: "Miktar",
      phaseTitle: "Oyun Aşaması",
      timestamp: "Zaman Damgası",
      isBetHappened: "Bahis Durumu",
      betsOpen: "Bahis Açık",
      betsClosed: "Bahis Kapalı",
      winner: "Kazanan",
      noWinnerYet: "Henüz kazanan yok",
      noCards: "Kart Yok",
      detail: "Oyun Detayları",
      userAction: {
        PLAYER_FOLD: "PAS",
        PLAYER_CALL: "GÖR",
        PLAYER_SIT: "OTUR",
        PLAYER_CHECK: "BEKLE",
        PLAYER_BET: "BAHİS",
      },
      phase: {
        TURN: "DÖNÜŞ",
        RIVER: "RIVER",
        FLOP: "FLOP",
        PRE_FLOP: "PRE-FLOP",
        INIT: "BAŞLANGIÇ",
        SHOWDOWN: "SHOWDOWN",
        DEAL: "DEAL",
      },
    },
    filters: {
      lobbyId: "Lobi",
      lobbyId_placeholder: "Lobi Giriniz",
      lobbyId_required: "Lütfen Lobi Giriniz",
      gameType: "Oyun Türü",
      gameType_placeholder: "Oyun Türü Giriniz",
      gameType_required: "Lütfen Oyun Türü Giriniz",
      tableName: "Masa Adı",
      tableName_placeholder: "Masa Adı Giriniz",
      tableName_required: "Lütfen Masa Adı Giriniz",
      gameId: "Tur ID",
      gameId_placeholder: "Tur ID Giriniz",
      gameId_required: "Lütfen Tur ID Giriniz",
      tableName_disabled: "Lütfen önce bir Lobi ve Oyun Türü seçin",
    },
    details: {
      generalGameInfoHeader: "Genel Oyun Bilgileri",
      gameStateHeader: "Oyun Durumu",
      playerInfoHeader: "Oyuncu Bilgileri",
      lobbyId: "Lobi ID",
      tableId: "Masa ID",
      roundId: "Tur ID",
      gameId: "Oyun ID",
      type: "Tür",
      limitType: "Limit Türü",
      totalSeats: "Toplam Koltuk",
      occupiedSeats: "Dolu Koltuk",
      minPlayers: "Minimum Oyuncu",
      maxBuyIn: "Maksimum Giriş Ücreti",
      minBuyIn: "Minimum Giriş Ücreti",
      phase: "Aşama",
      pot: "Pot",
      blinds: "Blinds",
      lastAction: "Son Aksiyon",
      lastActionAmount: "Son Aksiyon Miktarı",
      currentPlayer: "Sıradaki Oyuncu",
      timeoutDefaultAction: "Zaman Aşımı Aksiyonu",
      serviceFeePercent: "Hizmet Ücreti (%)",
      tipPot: "Bahşiş Potu",
      communityCards: "Topluluk Kartları",
      chips: "Çipler",
      balance: "Bakiye",
      seatIndex: "Koltuk Numarası",
      betAmount: "Bahis Miktarı",
      cards: "Kartlar",
      noCards: "Kart Yok",
      winner: "Kazanan",
      ante: "Ante",
      inProgress: "Oyun Devam Ediyor",
      inProgressTrue: "Evet",
      inProgressFalse: "Hayır",
      isActive: "Masa Aktif",
      isActiveTrue: "Aktif",
      isActiveFalse: "Pasif",
      isBetHappened: "Bahis Yapıldı",
      isBetHappenedTrue: "Evet",
      isBetHappenedFalse: "Hayır",
      isPasswordProtected: "Şifre Korumalı",
      isPasswordProtectedTrue: "Evet",
      isPasswordProtectedFalse: "Hayır",
      lastRaiseAmount: "Son Arttırma Miktarı",
    },
  },
};
