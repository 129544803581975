import { jsx as _jsx } from "react/jsx-runtime";
import IdView from "@/components/IdViewer";
import { DashOutlined } from "@ant-design/icons";
import { Badge, Button, theme } from "@atom/ui-kit";
import dayjs from "dayjs";
export var columns = function (handleEdit, handleDelete, handleOpenDetail, handleOpenTransactionDetail, t) { return [
    {
        title: t("gameManagement.columns.createdAt"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 180,
        render: function (createdAt) { return dayjs.unix(createdAt).format("YYYY-MM-DD HH:mm:ss"); },
    },
    {
        title: t("gameManagement.columns.name"),
        dataIndex: "name",
        key: "name",
        width: 150,
    },
    {
        title: t("gameManagement.columns.id"),
        dataIndex: "id",
        key: "id",
        width: 130,
        render: function (id) { return _jsx(IdView, { id: id }); },
    },
    {
        title: t("gameManagement.columns.state"),
        dataIndex: "state",
        key: "state",
        width: 120,
        render: function (state) { return (_jsx(Badge, { color: theme.colors.SecondaryBackgroundStrong, style: {
                color: theme.colors.SecondaryDark,
            }, badgeType: "default", count: t("transactionManagement.columns.phase.".concat(state === null || state === void 0 ? void 0 : state.phase)) })); },
    },
    {
        title: t("gameManagement.columns.pot"),
        dataIndex: "state",
        key: "state",
        width: 120,
        render: function (state) { return "N/A"; },
    },
    {
        title: t("gameManagement.columns.table_id"),
        dataIndex: "tableId",
        key: "tableId",
        width: 130,
        render: function (id) { return _jsx(IdView, { id: id }); },
    },
    {
        title: t("gameManagement.columns.lobby_id"),
        dataIndex: "lobbyId",
        key: "lobbyId",
        width: 130,
        render: function (id) { return _jsx(IdView, { id: id }); },
    },
    {
        title: t("gameManagement.columns.pot"),
        dataIndex: "state",
        key: "state",
        width: 120,
        render: function (state) { return "N/A"; },
    },
    {
        title: t("gameManagement.columns.updatedAt"),
        dataIndex: "updatedAt",
        key: "updatedAt",
        width: 180,
        render: function (updatedAt) { return dayjs.unix(updatedAt).format("YYYY-MM-DD HH:mm:ss"); },
    },
    {
        title: t("tableManagement.columns.action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 160,
        render: function (_, record) {
            return (_jsx(Button, { buttonSize: "small", buttonType: "primary", icon: _jsx(DashOutlined, {}), onClick: function () { return handleOpenTransactionDetail(record, "game"); }, children: t("root.transactions") }));
        },
    },
]; };
