import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOutlined } from "@ant-design/icons";
import { theme } from "@atom/ui-kit";
import { Typography } from "antd";
import { useState } from "react";
import { CopyIcon } from "../Icons"; // Assuming you have a CheckIcon component
var ID_VIEW_MAX_LENGTH = 9;
export var IdView = function (_a) {
    var id = _a.id, hiddenCopy = _a.hiddenCopy, length = _a.length;
    var _b = useState(false), copied = _b[0], setCopied = _b[1];
    var maxLength = length || ID_VIEW_MAX_LENGTH;
    var shortId = (id === null || id === void 0 ? void 0 : id.length) > maxLength ? "".concat(id === null || id === void 0 ? void 0 : id.slice(0, maxLength), "...") : id;
    var handleCopy = function () {
        navigator.clipboard.writeText(id).then(function () {
            setCopied(true);
            setTimeout(function () { return setCopied(false); }, 2000);
        });
    };
    return (_jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [_jsx(Typography.Text, { children: shortId }), !hiddenCopy && (copied ? _jsx(CheckOutlined, { style: { marginLeft: 12, fontSize: 20, color: theme.colors.Blue400 } }) : _jsx(CopyIcon, { onClick: handleCopy, style: { marginLeft: 12, cursor: "pointer", color: "#1890ff" } }))] }));
};
export default IdView;
