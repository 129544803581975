var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var TwoPeopleIcon = function (props) {
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "16", height: "17", viewBox: "0 0 16 17", fill: "none" }, props, { children: _jsx("path", { d: "M8.66663 13.9334V12.6001M8.66663 13.9334H0.666626V12.6001C0.666626 10.391 2.45749 8.6001 4.66663 8.6001C6.87577 8.6001 8.66663 10.391 8.66663 12.6001M8.66663 13.9334H14.6666V13.6001C14.6666 11.5751 13.025 9.93343 11 9.93343C9.96228 9.93343 9.02528 10.3645 8.35825 11.0573C8.55689 11.5321 8.66663 12.0533 8.66663 12.6001M6.66663 4.6001C6.66663 5.70467 5.7712 6.6001 4.66663 6.6001C3.56206 6.6001 2.66663 5.70467 2.66663 4.6001C2.66663 3.49553 3.56206 2.6001 4.66663 2.6001C5.7712 2.6001 6.66663 3.49553 6.66663 4.6001ZM12.6666 6.26676C12.6666 7.18724 11.9204 7.93343 11 7.93343C10.0795 7.93343 9.33329 7.18724 9.33329 6.26676C9.33329 5.34629 10.0795 4.6001 11 4.6001C11.9204 4.6001 12.6666 5.34629 12.6666 6.26676Z", stroke: "#4E4B66" }) })));
};
export default TwoPeopleIcon;
