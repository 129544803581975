var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var DeleteIcon = function (props) {
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" }, props, { children: _jsx("path", { d: "M14.1666 4.16634V3.33301C14.1666 1.9523 13.0473 0.833008 11.6666 0.833008H8.33325C6.95254 0.833008 5.83325 1.9523 5.83325 3.33301V4.16634M0.833252 4.16634H19.1666M12.4999 9.16634V13.333M7.49992 9.16634V13.333M2.49992 4.16634H17.4999L16.9478 15.2077C16.837 17.4253 15.0067 19.1663 12.7864 19.1663H7.21345C4.99315 19.1663 3.16287 17.4253 3.05199 15.2077L2.49992 4.16634Z", stroke: "#4E4B66", "stroke-width": "1.6", "stroke-linecap": "round" }) })));
};
export default DeleteIcon;
