import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IdViewer from "@/components/IdViewer";
import { MoreOutlined } from "@ant-design/icons";
import { Button } from "@atom/ui-kit";
import { Dropdown, Menu, Tag } from "antd";
import dayjs from "dayjs";
export var columns = function (handleEdit, handleDelete, t) { return [
    { title: t("lobbyManagement.columns.id"), dataIndex: "id", key: "id", render: function (id) { return _jsx(IdViewer, { id: id }); } },
    { title: t("lobbyManagement.columns.tenant"), dataIndex: "tenant", key: "tenant", render: function (text) { return text || "-"; } },
    { title: t("lobbyManagement.columns.name"), dataIndex: "name", key: "name", render: function (text) { return text || "-"; } },
    { title: t("lobbyManagement.columns.enabledGameTypes"), dataIndex: "enabledGameTypes", key: "enabledGameTypes", render: function (text) { return (text ? _jsx(Tag, { children: text }) : "-"); } },
    { title: t("lobbyManagement.columns.tables"), dataIndex: "tables", key: "tables", render: function (text) { return (text ? _jsx(Tag, { children: text }) : "-"); } },
    { title: t("lobbyManagement.columns.skincode"), dataIndex: "skincode", key: "skincode", render: function (text) { return text || "-"; } },
    { title: t("lobbyManagement.columns.rakeInfoUrl"), dataIndex: "rakeInfoUrl", key: "rakeInfoUrl", render: function (id) { return (id ? _jsx(IdViewer, { id: id }) : "-"); } },
    { title: t("lobbyManagement.columns.createdAt"), dataIndex: "createdAt", key: "createdAt", render: function (text) { return dayjs(text).format("YYYY-MM-DD HH:mm:ss") || "-"; } },
    { title: t("lobbyManagement.columns.updatedAt"), dataIndex: "updatedAt", key: "updatedAt", render: function (text) { return dayjs(text).format("YYYY-MM-DD HH:mm:ss") || "-"; } },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: function (_, record) {
            var menu = (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: function () { return handleEdit(record.id, record); }, children: t("root.edit_button") }, "edit"), _jsx(Menu.Item, { onClick: function () { return handleDelete(record.id, record.tenant); }, children: t("root.delete_button") }, "delete")] }));
            return (_jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(Button, { buttonType: "primary", buttonSize: "small", icon: _jsx(MoreOutlined, {}), children: t("root.actions_button") }) }));
        },
    },
]; };
