// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.atom-sticky-drawer-mask {
  background: rgba(182, 180, 189, 0.9);
  background-blend-mode: multiply;
  backdrop-filter: blur(7.5px);
  left: 200px !important;
  width: calc(100% - 200px);
  top: 145px;
}

.atom-sticky-drawer-mask-expand {
  left: 80px !important;
  width: calc(100% - 80px);
}

.atom-sticky-drawer {
  top: 145px;
}

.atom-sticky-drawer-content {
  background: #f7f7fc !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

html,
body {
  background: #fff;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  height: 100vh;
}

.ant-form-item .ant-form-item-label > label {
  color: var(--Alias-Grayscale-Header-Weak, #262338);
  font-feature-settings: "liga" off, "clig" off;
  /* Desktop/Link Small - Tight */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.75px;
}`, "",{"version":3,"sources":["webpack://./src/styles/custom.scss","webpack://./src/global.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,+BAAA;EACA,4BAAA;EACA,sBAAA;EACA,yBAAA;EACA,UAAA;ACEF;;ADCA;EACE,qBAAA;EACA,wBAAA;ACEF;;ADCA;EACE,UAAA;ACEF;;ADAA;EACE,8BAAA;ACGF;;AAjBA;EACE,sBAAA;EACA,UAAA;EACA,SAAA;EACA,iCAAA;AAoBF;;AAjBA;;EAEE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iCAAA;EACA,aAAA;AAoBF;;AAjBA;EACE,kDAAA;EACA,6CAAA;EAEA,+BAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,sBAAA;AAmBF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
