import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteIcon, EditIcon, FileStuctureIcon, FingerPrintIcon, PodCastIcon, TwoPeopleIcon } from "@/components/Icons";
import IdView from "@/components/IdViewer";
import { GAME_NAMES } from "@/constants/games";
import { MoreOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Menu, theme } from "@atom/ui-kit";
import { Space, Tooltip, Typography } from "antd";
import MoreInfoDrawer from "./_components/MoreInfoDrawer";
var Link = Typography.Link, Text = Typography.Text;
export var columns = function (handleOpenTransactionDetail, handlGameDetail, handleEdit, handleDelete, t, lobbyData) { return [
    {
        title: t("tableManagement.columns.lobby"),
        dataIndex: "lobbyId",
        key: "lobbyId",
        width: 130,
        render: function (lobbyId) {
            var _a, _b;
            var lobbyName = (_b = (_a = lobbyData === null || lobbyData === void 0 ? void 0 : lobbyData.items) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === lobbyId; })) === null || _b === void 0 ? void 0 : _b.name;
            return lobbyName ? (_jsx(Link, { style: {
                    color: theme.colors.GrayscaleBody,
                }, href: "/lobby-management", children: lobbyName })) : (_jsx(IdView, { id: lobbyId }));
        },
    },
    {
        title: t("tableManagement.columns.id"),
        dataIndex: "id",
        key: "id",
        width: 130,
        render: function (id) { return _jsx(IdView, { id: id }); },
    },
    {
        title: t("tableManagement.columns.name"),
        dataIndex: "name",
        key: "name",
        width: 150,
    },
    {
        title: t("tableManagement.columns.type"),
        dataIndex: "type",
        key: "type",
        width: 140,
        render: function (text) {
            console.log(GAME_NAMES[text]);
            return _jsx(Badge, { badgeType: "outline", defaultColor: text === "texasholdem" ? "Blue500" : "ErrorDefault", text: GAME_NAMES[text] });
        },
    },
    {
        title: t("tableManagement.columns.blinds"),
        dataIndex: "blinds",
        key: "blinds",
        width: 150,
        render: function (_, record) {
            var _a, _b, _c, _d, _e;
            return ((_a = record.gameConfig) === null || _a === void 0 ? void 0 : _a.blinds) && (_jsx(Tooltip, { title: t("tableManagement.columns.blinds"), children: _jsx(Badge, { color: theme.colors.SecondaryBackgroundStrong, style: {
                        color: theme.colors.SecondaryDark,
                    }, badgeType: "default", count: "".concat((_c = (_b = record.gameConfig) === null || _b === void 0 ? void 0 : _b.blinds) === null || _c === void 0 ? void 0 : _c.small, " ").concat(record.currency, " / ").concat((_e = (_d = record.gameConfig) === null || _d === void 0 ? void 0 : _d.blinds) === null || _e === void 0 ? void 0 : _e.big, " ").concat(record.currency) }) }));
        },
    },
    {
        title: t("tableManagement.columns.buyInRange"),
        dataIndex: "minBuyIn",
        key: "buyInRange",
        width: 180,
        render: function (_, record) {
            var _a, _b, _c;
            return ((_a = record.gameConfig) === null || _a === void 0 ? void 0 : _a.minBuyIn) && (_jsx(Tooltip, { title: t("tableManagement.columns.buyInRange"), children: _jsx(Badge, { color: theme.colors.WarningBackgroundStrong, style: {
                        color: theme.colors.WarningDefaultStrong,
                    }, badgeType: "default", count: "".concat((_b = record === null || record === void 0 ? void 0 : record.gameConfig) === null || _b === void 0 ? void 0 : _b.minBuyIn, " ").concat(record.currency, " - ").concat((_c = record === null || record === void 0 ? void 0 : record.gameConfig) === null || _c === void 0 ? void 0 : _c.maxBuyIn, " ").concat(record.currency) }) }));
        },
    },
    {
        title: t("tableManagement.columns.players"),
        dataIndex: "totalSeats",
        key: "totalSeats",
        width: 100,
        render: function (_, record) {
            var _a, _b;
            var activePlayers = (_a = record.activeGame) === null || _a === void 0 ? void 0 : _a.seats.filter(function (seat) { return seat.status === "FILLED"; }).length;
            return (_jsxs(Space, { align: "end", children: [_jsxs(Text, { style: {
                            fontSize: "15px",
                            lineHeight: "36px",
                        }, children: [activePlayers, "/", (_b = record.gameConfig) === null || _b === void 0 ? void 0 : _b.totalSeats] }), _jsx(TwoPeopleIcon, {})] }));
        },
    },
    {
        title: t("tableManagement.columns.action"),
        dataIndex: "action",
        key: "action",
        fixed: "right",
        width: 90,
        render: function (_, record) {
            var menu = (_jsxs(Menu, { children: [_jsx(Menu.Item, { children: _jsx(MoreInfoDrawer, { activeTab: "tableDetails", data: record, trigger: _jsxs(Space, { children: [_jsx("p", { style: {
                                            width: "100px",
                                        }, children: t("root.details") }), _jsx(FingerPrintIcon, {})] }) }) }, "details"), _jsx(Menu.Item, { onClick: function () { return handleOpenTransactionDetail(record); }, children: _jsxs(Space, { children: [_jsx("p", { style: {
                                        width: "100px",
                                    }, children: t("root.transactions") }), _jsx(FileStuctureIcon, {})] }) }, "transactions"), _jsx(Menu.Item, { children: _jsx(MoreInfoDrawer, { activeTab: "onlineTableView", data: record, trigger: _jsxs(Space, { children: [_jsx("p", { style: {
                                            width: "100px",
                                        }, children: t("root.online_view") }), _jsx(PodCastIcon, {})] }) }) }, "online_view"), _jsx(Menu.Item, { children: _jsx(MoreInfoDrawer, { data: record, activeTab: "edit", trigger: _jsxs(Space, { children: [_jsx("p", { style: {
                                            width: "100px",
                                        }, children: t("root.edit_button") }), _jsx(EditIcon, {})] }) }) }, "edit"), _jsx(Menu.Item, { children: _jsx(MoreInfoDrawer, { activeTab: "tableDetails", data: record, trigger: _jsxs(Space, { children: [_jsx("p", { style: {
                                            width: "100px",
                                        }, children: t("root.delete_button") }), _jsx(DeleteIcon, {})] }) }) }, "delete")] }));
            return (_jsx(Dropdown, { overlay: menu, trigger: ["click"], children: _jsx(Button, { buttonSize: "small", buttonType: "primary", icon: _jsx(MoreOutlined, {}), children: t("root.actions_button") }) }));
        },
    },
]; };
