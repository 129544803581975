export var TableMoreInfoTabKeys = {
    TableDetails: "tableDetails",
    TableRounds: "tableRounds",
    onlineTableView: "onlineTableView",
    Edit: "edit",
};
export var TableMoreInfoTabs = function (t) { return [
    {
        label: t("tableManagement.moreInfoDrawer.tabs.tableDetails"),
        key: TableMoreInfoTabKeys.TableDetails,
    },
    {
        label: t("tableManagement.moreInfoDrawer.tabs.tableRounds"),
        key: TableMoreInfoTabKeys.TableRounds,
    },
    {
        label: t("tableManagement.moreInfoDrawer.tabs.onlineTableView"),
        key: TableMoreInfoTabKeys.onlineTableView,
    },
]; };
