import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { deleteTenant } from "@/utils/string";
import { useTranslation } from "react-i18next";
import { Label, ListItem, OvalTableContainer, OvalTableWrapper, Player, PlayerList, Seat, Table, Username, Value } from "./OvalTable.style";
var OvalTable = function (_a) {
    var seats = _a.seats, totalSeats = _a.totalSeats, activePlayers = _a.activePlayers;
    var t = useTranslation().t;
    if (!seats)
        return null;
    return (_jsxs(OvalTableWrapper, { children: [_jsxs(OvalTableContainer, { children: [_jsx(Table, {}), seats.map(function (seat, index) { return (_jsx(Seat, { seatNumber: seat.seatNumber, isAvailable: seat.isAvailable, children: seat.seatNumber }, index)); })] }), _jsxs(ListItem, { children: [_jsx(Label, { children: t("tableManagement.moreInfoDrawer.detail.totalSeats") }), _jsx(Value, { children: totalSeats })] }, "totalSeats"), _jsxs(ListItem, { isOdd: true, children: [_jsx(Label, { children: t("tableManagement.moreInfoDrawer.detail.activePlayers") }), _jsx(Value, { children: activePlayers })] }, "activePlayers"), _jsx(PlayerList, { children: seats.map(function (seat, index) { return (_jsxs(Player, { children: [_jsx(Seat, { isAvailable: seat.isAvailable, style: { position: "unset" }, children: seat.seatNumber }, index), _jsx(Username, { isAvailable: seat.isAvailable, children: seat.username ? deleteTenant(seat.username) : t("root.empty") })] }, index)); }) })] }));
};
export default OvalTable;
