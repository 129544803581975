import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { RocketOutlined } from "@ant-design/icons";
import { Button, theme, Typography } from "@atom/ui-kit";
import { Col, Modal, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CommunityCards from "./_components/CommunityCards";
import ConfigDetails from "./_components/ConfigDetails";
import DeckDetails from "./_components/DeckDetails";
import GameDetails from "./_components/GameDetails";
import PlayerActions from "./_components/PlayerActions";
import PlayersTable from "./_components/PlayerTable";
var PokerSimulation = function (_a) {
    var _b;
    var pokerGameData = _a.pokerGameData, _c = _a.clickType, clickType = _c === void 0 ? "button" : _c, open = _a.open, onCLose = _a.onCLose;
    var t = useTranslation().t;
    var _d = useState(false), isModalVisible = _d[0], setIsModalVisible = _d[1];
    if (!pokerGameData)
        return null;
    var closeModal = function () {
        onCLose === null || onCLose === void 0 ? void 0 : onCLose();
        setIsModalVisible(false);
    };
    var renderClickElement = function (clickType) {
        switch (clickType) {
            case "button":
                return (_jsx(Button, { style: {
                        color: theme.colors.Purple700,
                        borderColor: theme.colors.Purple700,
                    }, buttonSize: "small", onClick: function () { return setIsModalVisible(true); }, children: _jsx(RocketOutlined, { style: { fontSize: "22px" }, color: theme.colors.Purple700, children: t("gameManagement.game.title") }) }));
            case "text":
                return (_jsxs(Typography.Text, { strong: true, style: {
                        cursor: "pointer",
                    }, onClick: function () { return setIsModalVisible(true); }, children: [_jsx(RocketOutlined, { style: { fontSize: "22px" }, color: theme.colors.Purple700 }), t("gameManagement.game.title")] }));
            case "icon":
                return (_jsx(RocketOutlined, { style: {
                        fontSize: "22px",
                        cursor: "pointer",
                        color: theme.colors.Purple700,
                    }, onClick: function () { return setIsModalVisible(true); } }));
            default:
                break;
        }
    };
    return (_jsxs(_Fragment, { children: [renderClickElement(clickType), _jsx(Modal, { title: t("gameManagement.game.title"), open: open || isModalVisible, onCancel: closeModal, footer: null, width: 1200, children: _jsxs(Row, { gutter: [16, 16], children: [_jsx(Col, { span: 12, children: _jsx(GameDetails, { pokerGameData: pokerGameData }) }), _jsx(Col, { span: 12, children: _jsx(ConfigDetails, { config: pokerGameData.config }) }), _jsx(Col, { span: 24, children: _jsx(PlayerActions, { state: pokerGameData.state }) }), _jsx(Col, { span: 24, children: _jsx(PlayersTable, { seats: pokerGameData.seats, dealerIndex: (_b = pokerGameData === null || pokerGameData === void 0 ? void 0 : pokerGameData.state) === null || _b === void 0 ? void 0 : _b.dealerIndex, currency: pokerGameData === null || pokerGameData === void 0 ? void 0 : pokerGameData.currency }) }), _jsx(Col, { span: 12, children: _jsx(CommunityCards, { communityCards: pokerGameData.state.communityCards }) }), _jsx(Col, { span: 12, children: _jsx(DeckDetails, { deck: pokerGameData.state.deck }) })] }) })] }));
};
export default PokerSimulation;
