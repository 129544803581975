import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IdViewer from "@/components/IdViewer";
import CurrencyViewer from "../CurrencyViewer";
import { Container, Label, ListItem, Value } from "./AtomList.style";
var AtomList = function (_a) {
    var items = _a.items, columnCount = _a.columnCount, rowCount = _a.rowCount;
    var renderValue = function (item) {
        switch (item.type) {
            case "id":
                return _jsx(IdViewer, { id: (item === null || item === void 0 ? void 0 : item.value) || "" });
            case "currency":
                return _jsx(CurrencyViewer, { children: item.value });
            default:
                return _jsx(Value, { children: item.value });
        }
    };
    return (_jsx(Container, { columnCount: columnCount, children: items
            .filter(function (item) { return item.value; })
            .map(function (item, index) {
            return item.value && (_jsxs(ListItem, { isOdd: index % 2 !== 0, rowCount: rowCount, children: [_jsx(Label, { children: item.label }), _jsx(Value, { color: item.valueColor, children: renderValue(item) })] }, index));
        }) }));
};
export default AtomList;
