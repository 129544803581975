var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import InfinityTable from "@/components/Infinitytable";
import { LocalStorageKeys } from "@/constants/localStorage";
import { useApi } from "@/hooks/useApi";
import { Card, Tabs } from "@atom/ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TransactionDetails } from "./_components/TransactionDetail";
import { columns } from "./TransactionManagement.columns";
import { TransactionTabKeys, TransactionTabs } from "./TransactionManagement.constants";
import TransactionManagementFilterSpace from "./TransactionManagement.filters";
import { fetchTransactions } from "./TransactionManagement.helpers";
import { TransactionContainer } from "./TransactionManagement.style";
//TODO rename tableName prop to tableId
var TransactionManagement = function (_a) {
    var isReusable = _a.isReusable, variant = _a.variant, props = __rest(_a, ["isReusable", "variant"]);
    var t = useTranslation().t;
    var _b = useState(TransactionTabKeys.Table), selectedTab = _b[0], setSelectedTab = _b[1];
    var _c = useApi({
        asyncFunction: fetchTransactions,
    }), callTransactionManagementList = _c.call, transactionManagementData = _c.data, transactionManagementLoading = _c.loading, error = _c.error, setData = _c.setData;
    var handleSearch = function (values, variant) {
        var payload = { tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming" };
        console.log(values, "values", selectedTab);
        switch (variant || selectedTab) {
            case TransactionTabKeys.Player:
                payload = __assign(__assign({}, payload), { userid: values.userId, startTime: values.dateRange[0].unix(), endTime: values.dateRange[1].unix() });
                break;
            case TransactionTabKeys.Game:
                payload = __assign(__assign({}, payload), { gameid: values.gameId });
                break;
            case TransactionTabKeys.Table:
                payload = __assign(__assign({}, payload), { tableid: values.tableName });
                break;
            case TransactionTabKeys.Tenant:
                payload = __assign({}, payload);
                break;
        }
        console.log(payload, "payload");
        callTransactionManagementList(__assign(__assign({}, payload), { selectedTab: variant || selectedTab }));
    };
    console.log(error);
    var prepareData = function (selectedTab) {
        var _a;
        switch (selectedTab) {
            case TransactionTabKeys.Player:
                return (transactionManagementData === null || transactionManagementData === void 0 ? void 0 : transactionManagementData.data) || [];
            case TransactionTabKeys.Game:
                return (transactionManagementData === null || transactionManagementData === void 0 ? void 0 : transactionManagementData.data) || [];
            case TransactionTabKeys.Table:
                return (transactionManagementData === null || transactionManagementData === void 0 ? void 0 : transactionManagementData.data) || [];
            case TransactionTabKeys.Tenant:
                return ((_a = transactionManagementData === null || transactionManagementData === void 0 ? void 0 : transactionManagementData.data) === null || _a === void 0 ? void 0 : _a.transactions) || [];
        }
    };
    useEffect(function () {
        if (selectedTab === TransactionTabKeys.Tenant) {
            callTransactionManagementList({ tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming", selectedTab: selectedTab });
        }
    }, [selectedTab]);
    useEffect(function () {
        console.log(variant, props, "variant");
        if (variant)
            handleSearch(props, variant);
    }, [variant, props.gameId, props.tableName, props.username]);
    return isReusable ? (_jsx(InfinityTable, { columns: columns(t), dataSource: prepareData(selectedTab), loading: transactionManagementLoading, rowClassName: function (record) { return (record.game.winner ? "table-winner-row" : ""); }, rowHoverable: false, rowKey: "id", containerStyle: { height: "100vh" }, expandable: {
            expandedRowRender: function (record) { return _jsx(TransactionDetails, { record: record, t: t }); },
        } })) : (_jsxs(_Fragment, { children: [_jsx(Tabs, { defaultActiveKey: selectedTab, items: TransactionTabs(t), onChange: function (value) {
                    setData({
                        data: [],
                    });
                    setSelectedTab(value);
                } }), _jsx(Card, { style: { padding: "20px 0", height: "80vh" }, children: _jsxs(TransactionContainer, { children: [selectedTab !== TransactionTabKeys.Tenant && _jsx(TransactionManagementFilterSpace, { selectedTab: selectedTab, onSearch: handleSearch }), _jsx(InfinityTable, { columns: columns(t), dataSource: prepareData(selectedTab), loading: transactionManagementLoading, rowClassName: function (record) { return (record.game.winner ? "table-winner-row" : ""); }, rowHoverable: false, rowKey: "id", expandable: {
                                expandedRowRender: function (record) { return _jsx(TransactionDetails, { record: record, t: t }); },
                            } })] }) })] }));
};
export default TransactionManagement;
