import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
var createStore = function (reducer, initialState) {
    var contextValues = [initialState, function () { return null; }];
    var Context = React.createContext(contextValues);
    var Provider = function (props) {
        var store = React.useReducer(reducer, initialState);
        return _jsx(Context.Provider, { value: store, children: props.children });
    };
    var useStore = function () { return React.useContext(Context); };
    return { Context: Context, Provider: Provider, Consumer: Context.Consumer, useStore: useStore };
};
export default createStore;
