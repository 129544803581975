export const tableManagement = {
  tableManagement: {
    edit: {
      title: "Edit Table",
      add_table: "Add Table",
      tenant: "Tenant",
      tenant_required: "Please enter a tenant",
      lobby_id: "Lobby ID",
      lobby_id_required: "Please select a lobby",
      allowedTenants: "Allowed Tenants",
      allowedTenants_required: "Please enter allowed tenants",
      name: "Name",
      name_required: "Please enter a name",
      skincode: "Skincode",
      skincode_required: "Please enter a skincode",
      category: "Category",
      category_required: "Please select a category",
      type: "Type",
      type_required: "Please select a type",
      currency: "Currency",
      currency_required: "Please enter currency",
      limitType: "Limit Type",
      limitType_required: "Please select a limit type",
      totalSeats: "Total Seats",
      totalSeats_required: "Please enter total seats",
      totalSeats_max_required: "Total seats should be less than or equal to current 9 seats",
      minPlayers: "Minimum Players",
      minPlayers_required: "Please enter minimum players",
      currentAllocatedSeats: "Current Allocated Seats",
      currentAllocatedSeats_required: "Please enter current allocated seats",
      minBuyIn: "Minimum Buy-In",
      minBuyIn_required: "Please enter minimum buy-in",
      maxBuyIn: "Maximum Buy-In",
      maxBuyIn_required: "Please enter maximum buy-in",
      blinds: "Blinds",
      blinds_small_required: "Enter small blind",
      blinds_big_required: "Enter big blind",
      ante: "Ante",
      timeLimitMs: "Time Limit (ms)",
      timeLimitMs_required: "Please enter time limit in ms",
      timeoutDefaultAction: "Timeout Default Action",
      timeoutDefaultAction_required: "Please select a default action",
      serviceFeePercent: "Service Fee (%)",
      serviceFeePercent_required: "Please enter service fee percent",
      autoRetable: "Auto Retable",
      highlighted: "Highlighted",
      gameInProgress: "Game In Progress",
      waitingPlayersCount: "Waiting Players Count",
      canJoinWaitingList: "Can Join Waiting List",
      canLeaveWaitingList: "Can Leave Waiting List",
      isPasswordProtected: "Is Password Protected",
      isAutoCreateGame: "Auto Create Game",
      isAutoCreateGame_required: "Please select auto create game",
      submit_button: "Add",
      success: "TableManagement added successfully",
      error: "Error adding TableManagement",
    },
    add: {
      steps: {
        step1: "Table Config",
        step2: "Game Config",
        step3: "Table Flags & Keys",
      },
      title: "Add Table",
      add_table: "Add Table",
      tenant: "Tenant",
      tenant_placeholder: "Enter tenant",
      tenant_required: "Please enter a tenant",
      lobby_id: "Lobby ID",
      lobby_id_placeholder: "Enter lobby",
      lobby_id_required: "Please select a lobby",
      allowedTenants: "Allowed Tenants",
      allowedTenants_placeholder: "Enter allowed tenants",
      allowedTenants_required: "Please enter allowed tenants",
      name: "Name",
      name_placeholder: "Enter name",
      name_required: "Please enter a name",
      skincode: "Skincode",
      skincode_placeholder: "Enter skincode",
      skincode_required: "Please enter a skincode",
      category: "Category",
      category_placeholder: "Select category",
      category_required: "Please select a category",
      type: "Type",
      type_placeholder: "Select type",
      type_required: "Please select a type",
      currency: "Currency",
      currency_placeholder: "Enter currency",
      currency_required: "Please enter currency",
      limitType: "Limit Type",
      limitType_placeholder: "Select limit type",
      limitType_required: "Please select a limit type",
      totalSeats: "Total Seats",
      totalSeats_placeholder: "Enter total seats",
      totalSeats_required: "Please enter total seats",
      totalSeats_max_required: "Total seats should be less than or equal to current 9 seats",
      minPlayers: "Minimum Players",
      minPlayers_placeholder: "Enter minimum players",
      minPlayers_required: "Please enter minimum players",
      currentAllocatedSeats: "Current Allocated Seats",
      currentAllocatedSeats_placeholder: "Enter current allocated seats",
      currentAllocatedSeats_required: "Please enter current allocated seats",
      minBuyIn: "Minimum Buy-In",
      minBuyIn_placeholder: "Enter minimum buy-in",
      minBuyIn_required: "Please enter minimum buy-in",
      maxBuyIn: "Maximum Buy-In",
      maxBuyIn_placeholder: "Enter maximum buy-in",
      maxBuyIn_required: "Please enter maximum buy-in",
      blinds: "Blinds",
      blinds_small_required: "Enter small blind",
      blinds_big_required: "Enter big blind",
      blind_placeholder: "Enter blinds",
      ante: "Ante",
      ante_placeholder: "Enter ante",
      timeLimitMs: "Time Limit (ms)",
      timeLimitMs_placeholder: "Enter time limit in ms",
      timeLimitMs_required: "Please enter time limit in ms",
      timeoutDefaultAction: "Timeout Default Action",
      timeoutDefaultAction_placeholder: "Select default action",
      timeoutDefaultAction_required: "Please select a default action",
      serviceFeePercent: "Service Fee (%)",
      serviceFeePercent_placeholder: "Enter service fee percent",
      serviceFeePercent_required: "Please enter service fee percent",
      autoRetable: "Auto Retable",
      autoRetable_required: "Please select auto retable",
      isAutoCreateGame: "Auto Create Game",
      isAutoCreateGame_required: "Please select auto create game",
      highlighted: "Highlighted",
      gameInProgress: "Game In Progress",
      waitingPlayersCount: "Waiting Players Count",
      waitingPlayersCount_placeholder: "Enter waiting players count",
      canJoinWaitingList: "Can Join Waiting List",
      canLeaveWaitingList: "Can Leave Waiting List",
      isPasswordProtected: "Is Password Protected",
      submit_button: "Add",
      success: "TableManagement added successfully",
      error: "Error adding TableManagement",
    },
    columns: {
      name: "Name",
      id: "Table ID",
      currency: "Currency",
      totalSeats: "Total Seats",

      tenant: "Tenant",
      buyInRange: "Buy-In Range",
      blinds: "Blinds",
      type: "Game Type",
      category: "Category",
      action: "Actions",
      players: "Players",
      lobby: "Lobby",
    },
    moreInfoDrawer: {
      tabs: {
        tableDetails: "Table Details",
        tableRounds: "Table Rounds",
        onlineTableView: "Online Table View",
      },
      detail: {
        tableInfo: "Table Information",
        name: "Table Name",
        type: "Table Type",
        currency: "Currency",
        blinds: "Big Blind - Small Blind",
        buyInRange: "Buy-In Range",
        lobbyId: "Lobby ID",
        additionalDetails: "Additional Details",
        updatedAt: "Updated At",
        createdAt: "Created At",
        createdBy: "Created By",
        id: "Table ID",
        isAutoCreateGame: "Auto-Created Game",
        highlighted: "Highlighted",
        version: "Version",
        serviceFeePercent: "Service Fee (%)",
        gameState: "Game State",
        skinCode: "Skin Code",
        timeoutDefaultAction: "Timeout Default Action",
        dealerIndex: "Dealer Index",
        dealerPosition: "Dealer Position",
        inProgress: "In Progress",
        isBetHappened: "Bet Happened",
        isEnded: "Ended",
        smallBlindIndex: "Small Blind Index",
        tipPot: "Tip Pot",
        sidePot: "Side Pot",
        waitingPlayersCount: "Waiting Players Count",
        pot: "Pot",
        phase: "Phase",
        lastAction: "Last Action",
        playerSeats: "Player Seats",
        totalSeats: "Total Seats",
        activePlayers: "Active Players",
      },
    },
  },
};
