var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  gap: 10px;\n  background-color: ", ";\n  border-radius: 8px;\n  height: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  gap: 10px;\n  background-color: ", ";\n  border-radius: 8px;\n  height: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.GrayscaleBackground;
}, function (_a) {
    var columnCount = _a.columnCount;
    return (columnCount ? "".concat(columnCount * 45, "px") : "auto");
});
export var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 500;\n"], ["\n  font-weight: 500;\n"])));
export var Value = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 600;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, color = _a.color;
    return color || theme.colors.PrimaryDefault;
});
export var ListItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px 0;\n  display: flex;\n  padding: 3px 15px;\n  justify-content: space-between;\n  align-items: flex-start;\n  align-self: stretch;\n  border-radius: 24px;\n  background: ", ";\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: 0.25px;\n  width: ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px 0;\n  display: flex;\n  padding: 3px 15px;\n  justify-content: space-between;\n  align-items: flex-start;\n  align-self: stretch;\n  border-radius: 24px;\n  background: ", ";\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: 0.25px;\n  width: ", ";\n"])), function (_a) {
    var theme = _a.theme, isOdd = _a.isOdd;
    return (isOdd ? theme.colors.GrayscaleInput : theme.colors.GrayscaleBackground);
}, function (_a) {
    var rowCount = _a.rowCount;
    return (rowCount ? "".concat(100 / rowCount, "%") : "100%");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
