var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var RefreshIcon = function (props) {
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "22", height: "22", viewBox: "0 0 22 22", fill: "none" }, props, { children: _jsx("path", { d: "M2.96694 8.72599C3.44716 7.05125 4.44525 5.57154 5.81814 4.49891C7.19104 3.42629 8.86826 2.81583 10.6094 2.75503C12.3506 2.69423 14.0663 3.1862 15.5107 4.16044C16.9551 5.13469 18.0539 6.54118 18.6498 8.17834M18.4984 14.2072C17.8778 15.6781 16.8438 16.9373 15.5217 17.8321C14.1997 18.727 12.6464 19.219 11.0502 19.2486C9.45404 19.2782 7.88359 18.844 6.52928 17.9987C5.17497 17.1535 4.09499 15.9335 3.42031 14.4866M5.23767 13.6903L2.88463 14.0393L1.75195 16.131M16.6222 8.91906L18.9752 8.57013L20.1079 6.47834", stroke: "#D9DBE9", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" }) })));
};
export default RefreshIcon;
