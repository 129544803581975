import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RefreshIcon from "@/components/Icons/RefreshIcon";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, theme } from "@atom/ui-kit";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
var GameManagementFilterSpace = function (_a) {
    var onSearch = _a.onSearch;
    var form = Form.useForm()[0];
    var t = useTranslation().t;
    var handleSearch = function (values) {
        if (onSearch)
            onSearch(values);
    };
    var handleFieldChange = debounce(function (changedFields) {
        var _a;
        var changedField = changedFields[0];
        if (changedField) {
            form.setFieldsValue({
                gameId: changedField.name[0] === "gameId" ? form.getFieldValue("gameId") : undefined,
                gameName: changedField.name[0] === "gameName" ? form.getFieldValue("gameName") : undefined,
            });
            onSearch === null || onSearch === void 0 ? void 0 : onSearch((_a = {},
                _a[changedField.name[0]] = changedField.value,
                _a));
        }
    }, 500);
    return (_jsx(Form, { form: form, layout: "vertical", onFieldsChange: handleFieldChange, onFinish: handleSearch, children: _jsxs(Row, { gutter: 5, children: [_jsx(Button, { buttonType: "primary", style: {
                        background: theme.colors.GrayscaleBody,
                        marginRight: "10px",
                    }, buttonSize: "small", icon: _jsx(RefreshIcon, {}), onClick: handleSearch }), _jsx(Col, { sm: 5, children: _jsx(Form.Item, { name: "gameId", children: _jsx(Input, { size: "small", addonIcon: _jsx(SearchOutlined, { style: { fontSize: "22px" } }), variant: "borderless", placeholder: t("gameManagement.filters.gameId_placeholder") }) }) }), _jsx(Col, { sm: 5, children: _jsx(Form.Item, { name: "gameName", children: _jsx(Input, { size: "small", variant: "borderless", addonIcon: _jsx(SearchOutlined, { style: { fontSize: "22px" } }), placeholder: t("gameManagement.filters.gameName_placeholder") }) }) }), _jsx(Col, { xs: 4, children: _jsx(Form.Item, { children: _jsx(Button, { buttonSize: "small", icon: _jsx(SearchOutlined, {}), buttonType: "primary", htmlType: "submit" }) }) })] }) }));
};
export default GameManagementFilterSpace;
