var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var CopyIcon = function (props) {
    return (_jsx("svg", __assign({ width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M13 5.31438V4.6001C13 2.94324 11.6569 1.6001 10 1.6001H4C2.34315 1.6001 1 2.94324 1 4.6001V11.6001C1 13.257 2.34315 14.6001 4 14.6001H6M14 23.6001H20C21.6569 23.6001 23 22.257 23 20.6001V13.6001C23 11.9432 21.6569 10.6001 20 10.6001H14C12.3431 10.6001 11 11.9432 11 13.6001V20.6001C11 22.257 12.3431 23.6001 14 23.6001Z", stroke: "#A0A3BD", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" }) })));
};
export default CopyIcon;
