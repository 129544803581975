import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Button, Col, Form, Row, Switch } from "@atom/ui-kit";
var TableFlagsForm = function (_a) {
    var onFinish = _a.onFinish, t = _a.t, loading = _a.loading, formValues = _a.formValues, onGoBack = _a.onGoBack;
    var form = Form.useForm()[0];
    useEffect(function () {
        if (formValues) {
            form.setFieldsValue(formValues);
        }
    }, [formValues]);
    return (_jsxs(Form, { onFinish: onFinish, layout: "vertical", initialValues: formValues ||
            {
                highlighted: true,
                canJoinWaitingList: false,
                canLeaveWaitingList: false,
                isAutoCreateGame: false,
                isPasswordProtected: false,
            }, children: [_jsxs(Row, { gutter: 10, children: [_jsx(Col, { span: 12, children: _jsx(Form.Item, { label: t("tableManagement.add.highlighted"), name: "highlighted", children: _jsx(Switch, {}) }) }), _jsx(Col, { span: 12, children: _jsx(Form.Item, { label: t("tableManagement.add.canJoinWaitingList"), name: "canJoinWaitingList", children: _jsx(Switch, {}) }) }), _jsx(Col, { span: 12, children: _jsx(Form.Item, { label: t("tableManagement.add.canLeaveWaitingList"), name: "canLeaveWaitingList", children: _jsx(Switch, {}) }) }), _jsx(Col, { span: 12, children: _jsx(Form.Item, { label: t("tableManagement.add.isAutoCreateGame"), name: "isAutoCreateGame", children: _jsx(Switch, {}) }) }), _jsx(Col, { span: 12, children: _jsx(Form.Item, { label: t("tableManagement.add.isPasswordProtected"), name: "isPasswordProtected", children: _jsx(Switch, {}) }) })] }), _jsxs(Row, { gutter: 10, style: {
                    justifyContent: "flex-end",
                }, children: [_jsx(Col, { span: 6, children: _jsx(Button, { block: true, size: "small", buttonType: "dashed", onClick: onGoBack, children: t("root.go_back_button") }) }), _jsx(Col, { span: 6, children: _jsx(Button, { block: true, buttonType: "primary", htmlType: "submit", loading: loading, children: t("root.save_button") }) })] })] }));
};
export default TableFlagsForm;
