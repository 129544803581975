var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var OvalTableWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  padding: 20px 30px;\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  padding: 20px 30px;\n"])));
export var OvalTableContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 290px;\n  height: 190px;\n  background-color: transparent;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: relative;\n  width: 290px;\n  height: 190px;\n  background-color: transparent;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var Seat = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  width: 40px;\n  height: 40px;\n  background-color: ", ";\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 20px;\n  letter-spacing: 0.35px;\n\n  ", "\n"], ["\n  position: absolute;\n  width: 40px;\n  height: 40px;\n  background-color: ", ";\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 20px;\n  letter-spacing: 0.35px;\n\n  ", "\n"])), function (_a) {
    var isAvailable = _a.isAvailable, theme = _a.theme;
    return (isAvailable ? theme.colors.GrayscaleLabel : theme.colors.Blue500);
}, function (_a) {
    var seatNumber = _a.seatNumber;
    switch (seatNumber) {
        case 1:
            return "top: 6%; left: 50%; transform: translate(-50%, 0);";
        case 2:
            return "top: 8%; right: 20%;";
        case 3:
            return "bottom: 40%; right: 5%;";
        case 4:
            return "bottom: 6%; right: 20%;";
        case 5:
            return "bottom: 6%; left: 45%;";
        case 6:
            return "bottom: 6%; left: 20%;";
        case 7:
            return "top: 40%; left: 5%;";
        case 8:
            return "top: 8%; left: 20%;";
        default:
            return "";
    }
});
export var Table = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 66.5px;\n  border: 1px solid ", ";\n  background: ", ";\n  width: 234px;\n  height: 133px;\n  flex-shrink: 0;\n"], ["\n  border-radius: 66.5px;\n  border: 1px solid ", ";\n  background: ", ";\n  width: 234px;\n  height: 133px;\n  flex-shrink: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.GrayscaleLine;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.GrayscaleInput;
});
export var Label = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 500;\n"], ["\n  font-weight: 500;\n"])));
export var Value = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 600;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, color = _a.color;
    return color || theme.colors.PrimaryDefault;
});
export var ListItem = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px 0;\n  display: flex;\n  padding: 3px 15px;\n  justify-content: space-between;\n  align-items: flex-start;\n  align-self: stretch;\n  border-radius: 24px;\n  background: ", ";\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: 0.25px;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px 0;\n  display: flex;\n  padding: 3px 15px;\n  justify-content: space-between;\n  align-items: flex-start;\n  align-self: stretch;\n  border-radius: 24px;\n  background: ", ";\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: 0.25px;\n  width: 100%;\n"])), function (_a) {
    var theme = _a.theme, isOdd = _a.isOdd;
    return (isOdd ? theme.colors.GrayscaleInput : theme.colors.GrayscaleBackground);
});
export var PlayerList = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  padding: 0px 10px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 5px;\n  width: 100%;\n"], ["\n  display: flex;\n  padding: 0px 10px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 5px;\n  width: 100%;\n"])));
export var Player = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  gap: 10px;\n  padding-bottom: 5px;\n  border-bottom: 1px solid ", ";\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  gap: 10px;\n  padding-bottom: 5px;\n  border-bottom: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.GrayscaleLine;
});
export var Username = styled.p(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: 0.25px;\n"], ["\n  color: ", ";\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: 0.25px;\n"])), function (_a) {
    var isAvailable = _a.isAvailable, theme = _a.theme;
    return (isAvailable ? theme.colors.GrayscaleLabel : theme.colors.Blue500);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
