var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var EditIcon = function (props) {
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "21", viewBox: "0 0 20 21", fill: "none" }, props, { children: _jsx("path", { d: "M11.8892 3.94304L16.3788 8.4326M14.1658 1.66661C14.1658 1.66661 16.2269 0.727887 18.0227 2.52371C19.8185 4.31954 18.8798 6.38066 18.8798 6.38066L6.88043 18.38L1.52356 19.0229L2.16638 13.666L14.1658 1.66661Z", stroke: "#4E4B66", "stroke-width": "1.6" }) })));
};
export default EditIcon;
