var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LobbyControllerApiFactory } from "@/apis/lobby-management";
import { TableControllerApiFactory } from "@/apis/table-management";
import InfinityTable from "@/components/Infinitytable";
import { LocalStorageKeys } from "@/constants/localStorage";
import { useApi } from "@/hooks/useApi";
import { Card, Divider, message, Modal } from "@atom/ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PokerSimulation from "../game-management/_components/PokerSimulation";
import { columns } from "./TableManagement.columns";
import TableManagementFilterSpace from "./TableManagement.filters";
import MoreInfoDrawer from "./_components/MoreInfoDrawer";
import TransactionDetailDrawer from "./_components/TransactionDetailDrawer";
var DEFAULT_PAGE_SIZE = 50;
var TableManagement = function () {
    var navigate = useNavigate();
    var t = useTranslation().t;
    var _a = useState({
        open: false,
        tableId: "",
        name: "",
    }), transactionDrawerState = _a[0], setTransactionDrawerState = _a[1];
    var _b = useState({
        open: false,
        recordData: null,
    }), pokerDetailModalState = _b[0], setPokerDetailModalState = _b[1];
    var _c = useState({
        tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming",
        limit: DEFAULT_PAGE_SIZE,
        requestType: "all",
    }), reqParams = _c[0], setReqParams = _c[1];
    var _d = useState([]), tableData = _d[0], setTableData = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var fetchTableData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 11, 12, 13]);
                    setLoading(true);
                    response = void 0;
                    _a = reqParams.requestType;
                    switch (_a) {
                        case "all": return [3 /*break*/, 1];
                        case "tableId": return [3 /*break*/, 3];
                        case "tableName": return [3 /*break*/, 5];
                        case "type": return [3 /*break*/, 7];
                    }
                    return [3 /*break*/, 9];
                case 1: return [4 /*yield*/, TableControllerApiFactory().tablesTenantGet(reqParams)];
                case 2:
                    response = (_b.sent());
                    setTableData(response.data.data.tables);
                    return [3 /*break*/, 10];
                case 3: return [4 /*yield*/, TableControllerApiFactory().tablesByidTenantIdGet({
                        id: reqParams.tableId,
                        tenant: reqParams.tenant,
                    })];
                case 4:
                    response = (_b.sent());
                    setTableData([response.data.data]);
                    return [3 /*break*/, 10];
                case 5: return [4 /*yield*/, TableControllerApiFactory().tablesBynameTenantNameGet({
                        name: reqParams.tableName,
                        tenant: reqParams.tenant,
                    })];
                case 6:
                    response = (_b.sent());
                    setTableData([response.data.data]);
                    return [3 /*break*/, 10];
                case 7: return [4 /*yield*/, TableControllerApiFactory().tablesBytypeTenantTypeGet({
                        type: reqParams.type,
                        tenant: reqParams.tenant,
                    })];
                case 8:
                    response = (_b.sent());
                    setTableData(response.data.data);
                    return [3 /*break*/, 10];
                case 9:
                    message.error("Invalid request type");
                    return [2 /*return*/];
                case 10: return [3 /*break*/, 13];
                case 11:
                    error_1 = _b.sent();
                    message.error("Failed to fetch table data");
                    return [3 /*break*/, 13];
                case 12:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 13: return [2 /*return*/];
            }
        });
    }); };
    var _f = useApi({
        asyncFunction: TableControllerApiFactory().tablesByidTenantIdDelete,
        successCallback: function (response) {
            message.success(t("root.delete_success"));
            fetchTableData();
        },
        errorCallback: function (error) {
            message.error(t("root.delete_error"));
        },
    }), callDeleteTableManagement = _f.call, deleteTableManagementLoading = _f.loading;
    var _g = useApi({
        asyncFunction: LobbyControllerApiFactory().lobbiesTenantGet,
    }), callLobbyManagementList = _g.call, lobbyManagementData = _g.data;
    var handleDelete = function (key) {
        Modal.confirm({
            title: t("root.delete_confirmation_title"),
            content: t("root.delete_confirmation_message"),
            okText: t("root.confirm"),
            okType: "danger",
            cancelText: t("root.cancel"),
            onOk: function () {
                callDeleteTableManagement({
                    id: key,
                    tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming",
                });
            },
        });
    };
    var handleEdit = function (key, tenant) {
        navigate("/table-management/".concat(key, "?tenant=").concat(tenant));
    };
    var handleAdd = function () {
        navigate("/table-management/add");
    };
    var handlGameDetail = function (recordData) {
        setPokerDetailModalState({ open: true, recordData: recordData });
    };
    var handleOpenTransactionDetail = function (recordData) {
        setTransactionDrawerState({ open: true, tableId: recordData === null || recordData === void 0 ? void 0 : recordData.id, name: recordData === null || recordData === void 0 ? void 0 : recordData.name });
    };
    var handleSearch = function (values) {
        setReqParams(__assign(__assign(__assign({}, reqParams), values), { requestType: values.type ? "type" : values.tableId ? "tableId" : values.tableName ? "tableName" : "all" }));
    };
    useEffect(function () {
        fetchTableData();
        callLobbyManagementList({
            tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming",
        });
    }, [reqParams]);
    return (_jsxs(Card, { style: {
            padding: "20px 0",
            height: "80vh",
        }, children: [_jsx(TableManagementFilterSpace, { onAddNewData: handleAdd, onSearch: handleSearch }), _jsx(Divider, {}), _jsx(InfinityTable, { columns: columns(handleOpenTransactionDetail, handlGameDetail, handleEdit, handleDelete, t, lobbyManagementData === null || lobbyManagementData === void 0 ? void 0 : lobbyManagementData.data), dataSource: tableData || [], loading: loading || deleteTableManagementLoading }), _jsx(TransactionDetailDrawer, __assign({ onCLose: function () { return setTransactionDrawerState(__assign(__assign({}, transactionDrawerState), { open: false, tableId: "", name: "" })); } }, transactionDrawerState)), _jsx(PokerSimulation, { clickType: null, open: pokerDetailModalState.open, pokerGameData: pokerDetailModalState === null || pokerDetailModalState === void 0 ? void 0 : pokerDetailModalState.recordData, onCLose: function () { return setPokerDetailModalState(__assign(__assign({}, pokerDetailModalState), { open: false, recordData: null })); } }), _jsx(MoreInfoDrawer, { data: {
                    index: 0,
                    version: 0,
                    currency: "TRY",
                    createdAt: 1737553293,
                    activeGame: {
                        isActive: true,
                        type: "turkishpoker",
                        version: 0,
                        seats: [
                            {
                                hasActed: true,
                                betAmount: 2,
                                cards: ["AC", "10S", "10D", "KC", "JH"],
                                chips: 98,
                                index: 0,
                                meetsMinimumRequirement: true,
                                isActive: true,
                                actions: {
                                    last: {
                                        action: "BLINDS_POSTED",
                                        amount: 2,
                                        baseAmount: 2,
                                    },
                                    possible: ["PLAYER_CHECK", "PLAYER_RAISE", "PLAYER_FOLD"],
                                },
                                status: "FILLED",
                                player: {
                                    nickname: "matthiastest1",
                                    balance: 9060,
                                    userId: "etorobet#matthiastest1",
                                    tenant: "etorobet",
                                    username: "etorobet#matthiastest1",
                                },
                            },
                            {
                                hasActed: true,
                                betAmount: 2,
                                cards: ["JS", "QS", "KD", "JD", "10C"],
                                chips: 98,
                                index: 1,
                                meetsMinimumRequirement: true,
                                isActive: true,
                                actions: {
                                    last: {
                                        action: "BLINDS_POSTED",
                                        amount: 2,
                                        baseAmount: 2,
                                    },
                                },
                                status: "FILLED",
                                player: {
                                    nickname: "cindypoker",
                                    balance: 8840,
                                    userId: "etorobet#cindypoker",
                                    tenant: "etorobet",
                                    username: "etorobet#cindypoker",
                                },
                            },
                            {
                                index: 2,
                                actions: {},
                                status: "EMPTY",
                            },
                            {
                                index: 3,
                                actions: {},
                                status: "EMPTY",
                            },
                            {
                                index: 4,
                                actions: {},
                                status: "EMPTY",
                            },
                            {
                                index: 4,
                                actions: {},
                                status: "EMPTY",
                            },
                            {
                                index: 4,
                                actions: {},
                                status: "EMPTY",
                            },
                            {
                                index: 4,
                                actions: {},
                                status: "EMPTY",
                            },
                        ],
                        lobbyId: "5ee1ca70-9220-49af-bc3b-152f3c57b239",
                        roundIndex: 0,
                        createdAt: 1737553293,
                        createdBy: "wisegaming#matthiasgodmin",
                        name: "MattsTableTRYs",
                        tableId: "047d23e6-7978-41d9-8b5b-b27d3373c6c3",
                        currency: "EUR",
                        state: {
                            phase: "DEAL",
                            currentTurn: {
                                hasActed: true,
                                betAmount: 2,
                                cards: ["AC", "10S", "10D", "KC", "JH"],
                                chips: 98,
                                index: 0,
                                meetsMinimumRequirement: true,
                                isActive: true,
                                actions: {
                                    last: {
                                        action: "BLINDS_POSTED",
                                        amount: 2,
                                        baseAmount: 2,
                                    },
                                    possible: ["PLAYER_CHECK", "PLAYER_RAISE", "PLAYER_FOLD"],
                                },
                                status: "FILLED",
                                player: {
                                    nickname: "matthiastest1",
                                    balance: 9060,
                                    userId: "etorobet#matthiastest1",
                                    tenant: "etorobet",
                                    username: "etorobet#matthiastest1",
                                },
                            },
                            bigBlindIndex: 1,
                            smallBlindIndex: 0,
                            inProgress: true,
                            deck: ["KH", "AH", "AS", "QH", "KS", "QC", "JC", "QD", "10H", "AD"],
                            dealerIndex: 1,
                            raiseAction: {
                                amount: 2,
                                baseAmount: 2,
                            },
                            maxTotalBetAmount: 2,
                            pot: 4,
                            lastAction: {
                                action: "BLINDS_POSTED",
                                amount: 2,
                            },
                            sidePot: 0,
                            tipPot: 0,
                            currentAllocatedSeats: 2,
                            dealerPosition: 1,
                        },
                        id: "6590c218-5e7b-4f90-affb-94c515e63822",
                        category: "poker",
                        config: {
                            canLeaveWaitingList: true,
                            minPlayers: 2,
                            minBuyIn: 100,
                            timeLimitMs: 360000,
                            canJoinWaitingList: true,
                            limitType: "fixedlimit",
                            totalSeats: 5,
                            ante: 2,
                            isPasswordProtected: false,
                            timeoutDefaultAction: "Fold",
                            skinCode: "etorobet",
                            serviceFeePercent: 5,
                            maxBuyIn: 500,
                        },
                        roundId: "6590c218-5e7b-4f90-affb-94c515e63822",
                        tenant: "etorobet",
                        updatedAt: 1737553293,
                    },
                    gameConfig: {
                        canLeaveWaitingList: true,
                        minPlayers: 2,
                        minBuyIn: 100,
                        timeLimitMs: 360000,
                        canJoinWaitingList: true,
                        limitType: "fixedlimit",
                        totalSeats: 5,
                        ante: 2,
                        isPasswordProtected: false,
                        timeoutDefaultAction: "Fold",
                        skinCode: "etorobet",
                        serviceFeePercent: 5,
                        maxBuyIn: 500,
                    },
                    createdBy: "wisegaming#matthiasgodmin",
                    name: "MattsTableTRYs",
                    lobbyId: "5ee1ca70-9220-49af-bc3b-152f3c57b239",
                    tenant: "etorobet",
                    highlighted: true,
                    updatedAt: 1737553293,
                    category: "poker",
                    isAutoCreateGame: true,
                    id: "047d23e6-7978-41d9-8b5b-b27d3373c6c3",
                    type: "turkishpoker",
                }, trigger: _jsx(_Fragment, {}) })] }));
};
export default TableManagement;
