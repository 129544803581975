import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DollarCircleOutlined, SettingOutlined, TeamOutlined } from "@ant-design/icons";
import { Collapse, List, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
var Panel = Collapse.Panel;
var Text = Typography.Text;
var ConfigDetails = function (_a) {
    var _b, _c;
    var config = _a.config;
    var t = useTranslation().t;
    var configData = [
        {
            label: t("gameManagement.game.blinds.small"),
            value: (_jsxs(Tag, { color: "gold", children: [_jsx(DollarCircleOutlined, {}), " ", (_b = config === null || config === void 0 ? void 0 : config.blinds) === null || _b === void 0 ? void 0 : _b.small, " \u20AC"] })),
        },
        {
            label: t("gameManagement.game.blinds.big"),
            value: (_jsxs(Tag, { color: "volcano", children: [_jsx(DollarCircleOutlined, {}), " ", (_c = config === null || config === void 0 ? void 0 : config.blinds) === null || _c === void 0 ? void 0 : _c.big, " \u20AC"] })),
        },
        {
            label: t("gameManagement.game.maxBuyIn"),
            value: (_jsxs(Tag, { color: "green", children: [_jsx(SettingOutlined, {}), " ", config === null || config === void 0 ? void 0 : config.maxBuyIn, " \u20AC"] })),
        },
        {
            label: t("gameManagement.game.totalSeats"),
            value: (_jsxs(Tag, { color: "blue", children: [_jsx(TeamOutlined, {}), " ", config.totalSeats] })),
        },
    ];
    return (_jsx(Collapse, { children: _jsx(Panel, { header: t("gameManagement.game.config"), children: _jsx(List, { dataSource: configData, renderItem: function (item) { return (_jsxs(List.Item, { children: [_jsxs(Text, { strong: true, children: [item.label, ":"] }), " ", item.value] })); } }) }, "1") }));
};
export default ConfigDetails;
