var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from "@atom/ui-kit";
export var formatCurrency = function (amount, currency) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: currency }).format(amount);
};
export var CurrencyViewer = function (_a) {
    var currency = _a.currency, amount1 = _a.amount1, amount2 = _a.amount2, _b = _a.splitter, splitter = _b === void 0 ? "/" : _b, props = __rest(_a, ["currency", "amount1", "amount2", "splitter"]);
    var formattedAmount1 = amount1 !== undefined ? formatCurrency(amount1, currency) : "-";
    var formattedAmount2 = amount2 !== undefined ? formatCurrency(amount2, currency) : "";
    return _jsx(Typography.Text, __assign({}, props, { children: formattedAmount2 ? "".concat(formattedAmount1).concat(splitter).concat(formattedAmount2) : formattedAmount1 }));
};
export default CurrencyViewer;
