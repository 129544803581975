import { jsx as _jsx } from "react/jsx-runtime";
import TransactionManagement from "@/pages/transaction-management";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { Container } from "./TransactionDetailDrawer.style";
var TransactionDetailDrawer = function (_a) {
    var open = _a.open, onCLose = _a.onCLose, name = _a.name, tableId = _a.tableId;
    var t = useTranslation().t;
    return (_jsx(Drawer, { width: 1500, open: open, onClose: onCLose, closeIcon: _jsx(ArrowLeftOutlined, {}), title: name + " " + t("root.transactions"), children: _jsx(Container, { children: _jsx(TransactionManagement, { isReusable: true, variant: "table", tableName: tableId }) }) }));
};
export default TransactionDetailDrawer;
