import { jsx as _jsx } from "react/jsx-runtime";
import CardPopover from "@/pages/transaction-management/_components/CardPopover";
import { getCardImageURL } from "@/pages/transaction-management/TransactionManagement.helpers";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
var DeckDetails = function (_a) {
    var deck = _a.deck;
    var t = useTranslation().t;
    return (_jsx(Card, { title: t("gameManagement.game.deck"), bordered: true, style: {
            position: "relative",
            height: "150px",
            overflowY: "hidden",
            overflowX: "auto",
        }, children: deck === null || deck === void 0 ? void 0 : deck.map(function (card, index) { return (_jsx("div", { style: {
                position: "absolute",
                left: "".concat(index * 12, "px"),
                zIndex: deck.length - index,
                transform: "rotate(".concat(index % 2 === 0 ? -2 : 2, "deg)"),
                transition: "all 0.3s ease",
            }, children: _jsx(CardPopover, { cardUrl: getCardImageURL(card) }) }, index)); }) }));
};
export default DeckDetails;
