import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Typography } from "@atom/ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./GoBack.style";
var GoBack = function (_a) {
    var _b;
    var title = _a.title, onClick = _a.onClick;
    var location = useLocation();
    var navigate = useNavigate();
    var pathSnippets = location.pathname.split("/").filter(function (i) { return i; });
    if (pathSnippets.length <= 1) {
        return null;
    }
    var currentTitle = title || ((_b = pathSnippets[pathSnippets.length - 1]) === null || _b === void 0 ? void 0 : _b.replace(/-/g, " ").toUpperCase());
    var handleGoBack = function () {
        if (onClick) {
            console.log("asd");
            onClick();
        }
        else {
            navigate(-1);
        }
    };
    return (_jsxs(Container, { onClick: handleGoBack, children: [_jsx(ArrowLeftOutlined, { style: { marginRight: 8 } }), " ", _jsx("div", { children: _jsx(Typography.Title, { style: { margin: 0 }, children: currentTitle }) })] }));
};
export default GoBack;
