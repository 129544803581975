import { jsx as _jsx } from "react/jsx-runtime";
import CurrencyViewer from "@/components/CurrencyViewer";
import { Space, Tag, Typography } from "@atom/ui-kit";
import dayjs from "dayjs";
import PokerSimulation from "../game-management/_components/PokerSimulation";
import CardPopover from "./_components/CardPopover";
import PlayerCardPopover from "./_components/PlayerCardPopover";
import { ActionTypeTagColors } from "./TransactionManagement.constants";
import { getCardImageURL } from "./TransactionManagement.helpers";
var Text = Typography.Text;
export var columns = function (t) { return [
    {
        title: t("transactionManagement.columns.username"),
        dataIndex: "username",
        key: "username",
        width: 120,
        render: function (_, record) { var _a, _b; return ((_b = (_a = record === null || record === void 0 ? void 0 : record.session) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.username) || "-"; },
    },
    {
        title: t("transactionManagement.columns.tableStatus"),
        dataIndex: "tableStatus",
        key: "tableStatus",
        width: 120,
        render: function (_, record) {
            var _a, _b;
            var totalSeats = ((_a = record.game.config) === null || _a === void 0 ? void 0 : _a.totalSeats) || 0;
            var occupiedSeats = ((_b = record.game.seats) === null || _b === void 0 ? void 0 : _b.filter(function (seat) { return seat.status === "FILLED"; }).length) || 0;
            return "".concat(occupiedSeats, "/").concat(totalSeats);
        },
    },
    {
        title: t("transactionManagement.columns.playerCards"),
        dataIndex: "players",
        key: "playerCards",
        width: 150,
        render: function (_, record) {
            var _a;
            var playerSeats = (_a = record.game.seats) === null || _a === void 0 ? void 0 : _a.filter(function (seat) { var _a; return seat.status === "FILLED" && ((_a = seat.player) === null || _a === void 0 ? void 0 : _a.userId) === record.session.user.userId; });
            return _jsx(Space, { children: (playerSeats === null || playerSeats === void 0 ? void 0 : playerSeats.map(function (seat) { var _a; return (_a = seat.cards) === null || _a === void 0 ? void 0 : _a.map(function (card) { return _jsx(CardPopover, { cardUrl: getCardImageURL(card) }, card); }); })) || t("transactionManagement.columns.noCards") });
        },
    },
    {
        title: t("transactionManagement.columns.communityCards"),
        dataIndex: "communityCards",
        key: "communityCards",
        width: 150,
        render: function (_, record) {
            var _a;
            return (_jsx("div", { style: { display: "flex", gap: "5px" }, children: ((_a = record.game.state.communityCards) === null || _a === void 0 ? void 0 : _a.map(function (card) { return _jsx(CardPopover, { cardUrl: getCardImageURL(card) }, card); })) || t("transactionManagement.columns.noCards") }));
        },
    },
    {
        title: t("transactionManagement.columns.otherPlayerCards"),
        dataIndex: "players",
        key: "otherPlayerCards",
        width: 200,
        render: function (_, record) {
            var _a;
            return (_jsx(Space, { children: ((_a = record.game.seats) === null || _a === void 0 ? void 0 : _a.filter(function (seat) { return seat.status === "FILLED"; }).map(function (seat) { var _a; return _jsx(PlayerCardPopover, { cards: seat.cards || [], username: ((_a = seat.player) === null || _a === void 0 ? void 0 : _a.nickname) || "-" }, seat.index); })) ||
                    t("transactionManagement.columns.noOtherPlayerCards") }));
        },
    },
    {
        title: t("transactionManagement.columns.type"),
        dataIndex: "type",
        key: "type",
        width: 120,
        render: function (value) { return _jsx(Tag, { color: ActionTypeTagColors[value] || "default", children: t("transactionManagement.columns.userAction.".concat(value)) }); },
    },
    {
        title: t("transactionManagement.columns.amount"),
        dataIndex: "amount",
        key: "amount",
        width: 100,
        render: function (value) { return _jsx(CurrencyViewer, { currency: "TRY", amount1: value || 0 }); },
    },
    {
        title: t("transactionManagement.columns.phaseTitle"),
        dataIndex: "currentPhase",
        key: "currentPhase",
        width: 120,
        render: function (_, record) { return t("transactionManagement.columns.phase.".concat(record.game.state.phase || "unknown")); },
    },
    {
        title: t("transactionManagement.columns.timestamp"),
        dataIndex: "timestamp",
        key: "timestamp",
        width: 150,
        render: function (timestamp) { return dayjs(timestamp * 1000).format("YYYY-MM-DD HH:mm:ss"); },
    },
    {
        title: t("transactionManagement.columns.detail"),
        dataIndex: "detail",
        key: "detail",
        width: 150,
        render: function (timestamp, record) {
            return _jsx(PokerSimulation, { pokerGameData: record.game, clickType: "text" });
        },
    },
]; };
