/* tslint:disable */
/* eslint-disable */
/**
 * PokerWise Game Management
 * API for managing PokerWise games.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export var GameTypeEnum = {
    Texasholdem: 'texasholdem',
    Turkishpoker: 'turkishpoker',
    Omaha4: 'omaha4',
    Omaha5: 'omaha5'
};
