import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { theme } from "@atom/ui-kit";
import { Breadcrumb as AntdBreadCrumb } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./BreadCrumb.style";
var BreadCrumb = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var pathSnippets = location.pathname.split("/").filter(function (i) { return i; });
    var t = useTranslation().t;
    var breadcrumbItems = pathSnippets.map(function (_, index) {
        var url = "/".concat(pathSnippets.slice(0, index + 1).join("/"));
        var isLastItem = index === pathSnippets.length - 1;
        return (_jsx(AntdBreadCrumb.Item, { children: _jsx("span", { onClick: function () { return navigate(url); }, style: { cursor: "pointer" }, children: t("sidebar.menu." + pathSnippets[index]) }) }, url));
    });
    if (location.pathname.includes("transaction")) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Container, { children: _jsxs(AntdBreadCrumb, { separator: ">", children: [_jsx(AntdBreadCrumb.Item, { onClick: function () { return navigate("/"); }, children: _jsx("span", { onClick: function () { return navigate("/"); }, style: { cursor: "pointer", color: theme.colors.PrimaryDefault }, children: t("sidebar.menu.home") }) }), breadcrumbItems] }) }));
};
export default BreadCrumb;
