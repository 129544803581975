import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CurrencyViewer from "@/components/CurrencyViewer";
import IdViewer from "@/components/IdViewer";
import { List, Tag, Typography } from "antd";
import { getCardImageURL } from "../../TransactionManagement.helpers";
import CardPopover from "../CardPopover";
import { TransactionDetailsContainer } from "./TransactionDetails.style";
var Text = Typography.Text;
export var TransactionDetails = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var record = _a.record, t = _a.t;
    var game = record.game;
    var generalGameInfo = [
        { title: t("transactionManagement.details.name"), value: (game === null || game === void 0 ? void 0 : game.name) || t("transactionManagement.details.unknown") },
        { title: t("transactionManagement.details.lobbyId"), value: _jsx(IdViewer, { id: game === null || game === void 0 ? void 0 : game.lobbyId }) },
        { title: t("transactionManagement.details.tableId"), value: _jsx(IdViewer, { id: game === null || game === void 0 ? void 0 : game.tableId }) },
        { title: t("transactionManagement.details.roundId"), value: _jsx(IdViewer, { id: game === null || game === void 0 ? void 0 : game.roundId }) },
        { title: t("transactionManagement.details.gameId"), value: _jsx(IdViewer, { id: record.gameId }) },
        { title: t("transactionManagement.details.type"), value: (game === null || game === void 0 ? void 0 : game.type) || "-" },
        { title: t("transactionManagement.details.totalSeats"), value: ((_b = game === null || game === void 0 ? void 0 : game.config) === null || _b === void 0 ? void 0 : _b.totalSeats) || "-" },
        { title: t("transactionManagement.details.occupiedSeats"), value: ((_c = game === null || game === void 0 ? void 0 : game.seats) === null || _c === void 0 ? void 0 : _c.filter(function (seat) { return seat.status === "FILLED"; }).length) || 0 },
        {
            title: t("transactionManagement.details.maxBuyIn"),
            value: _jsx(CurrencyViewer, { currency: game === null || game === void 0 ? void 0 : game.currency, amount1: ((_d = game === null || game === void 0 ? void 0 : game.config) === null || _d === void 0 ? void 0 : _d.maxBuyIn) || 0 }),
        },
        {
            title: t("transactionManagement.details.minBuyIn"),
            value: _jsx(CurrencyViewer, { currency: game === null || game === void 0 ? void 0 : game.currency, amount1: ((_e = game === null || game === void 0 ? void 0 : game.config) === null || _e === void 0 ? void 0 : _e.minBuyIn) || 0 }),
        },
    ];
    var gameState = [
        { title: t("transactionManagement.details.pot"), value: _jsx(CurrencyViewer, { currency: game === null || game === void 0 ? void 0 : game.currency, amount1: ((_f = game === null || game === void 0 ? void 0 : game.state) === null || _f === void 0 ? void 0 : _f.pot) || 0 }) },
        { title: t("transactionManagement.details.phase"), value: ((_g = game === null || game === void 0 ? void 0 : game.state) === null || _g === void 0 ? void 0 : _g.phase) || t("transactionManagement.details.unknown") },
        {
            title: t("transactionManagement.details.communityCards"),
            value: ((_j = (_h = game === null || game === void 0 ? void 0 : game.state) === null || _h === void 0 ? void 0 : _h.communityCards) === null || _j === void 0 ? void 0 : _j.length) ? (_jsx("div", { style: { display: "flex", gap: "5px" }, children: (_k = game === null || game === void 0 ? void 0 : game.state) === null || _k === void 0 ? void 0 : _k.communityCards.map(function (card) { return (_jsx(CardPopover, { cardUrl: getCardImageURL(card) }, card)); }) })) : (t("transactionManagement.details.noCards")),
        },
        {
            title: t("transactionManagement.details.inProgress"),
            value: ((_l = game === null || game === void 0 ? void 0 : game.state) === null || _l === void 0 ? void 0 : _l.inProgress) ? _jsx(Tag, { color: "green", children: t("transactionManagement.details.inProgressTrue") }) : _jsx(Tag, { color: "red", children: t("transactionManagement.details.inProgressFalse") }),
        },
    ];
    var playerInfo = (_m = (Array.isArray(game === null || game === void 0 ? void 0 : game.seats) ? game === null || game === void 0 ? void 0 : game.seats : [])) === null || _m === void 0 ? void 0 : _m.filter(function (seat) { return seat.status === "FILLED"; }).map(function (seat) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return ({
            title: "".concat(((_a = seat === null || seat === void 0 ? void 0 : seat.player) === null || _a === void 0 ? void 0 : _a.nickname) || ((_b = seat === null || seat === void 0 ? void 0 : seat.player) === null || _b === void 0 ? void 0 : _b.username), " (").concat(((_c = seat.player) === null || _c === void 0 ? void 0 : _c.userId) || t("transactionManagement.details.unknown"), ")"),
            value: (_jsxs("div", { children: [((_d = seat.player) === null || _d === void 0 ? void 0 : _d.userId) === ((_g = (_f = (_e = game === null || game === void 0 ? void 0 : game.state) === null || _e === void 0 ? void 0 : _e.currentTurn) === null || _f === void 0 ? void 0 : _f.player) === null || _g === void 0 ? void 0 : _g.userId) && (_jsx(Tag, { color: "blue", style: { marginBottom: "5px" }, children: t("transactionManagement.details.currentPlayer") })), _jsxs(Text, { children: [t("transactionManagement.details.chips"), ": "] }), " ", _jsx(CurrencyViewer, { currency: game === null || game === void 0 ? void 0 : game.currency, amount1: seat.chips || 0 }), _jsx("br", {}), _jsxs(Text, { children: [t("transactionManagement.details.betAmount"), ": "] }), " ", _jsx(CurrencyViewer, { currency: game === null || game === void 0 ? void 0 : game.currency, amount1: seat.betAmount || 0 }), _jsx("br", {}), _jsxs(Text, { children: [t("transactionManagement.details.cards"), ": "] }), ((_h = seat.cards) === null || _h === void 0 ? void 0 : _h.length) ? (_jsx("div", { style: { display: "flex", gap: "5px", marginTop: "5px" }, children: seat.cards.map(function (card) { return (_jsx(CardPopover, { cardUrl: getCardImageURL(card) }, card)); }) })) : (_jsx(Text, { children: t("transactionManagement.details.noCards") }))] })),
        });
    });
    return (_jsxs(TransactionDetailsContainer, { children: [_jsx(List, { size: "small", bordered: true, header: _jsx(Text, { strong: true, children: t("transactionManagement.details.generalGameInfoHeader") }), dataSource: generalGameInfo, renderItem: function (item) { return (_jsxs(List.Item, { children: [_jsx(Text, { strong: true, children: item.title }), " : ", item.value] })); } }), _jsx(List, { size: "small", bordered: true, header: _jsx(Text, { strong: true, children: t("transactionManagement.details.gameStateHeader") }), dataSource: gameState, renderItem: function (item) { return (_jsxs(List.Item, { children: [_jsx(Text, { strong: true, children: item.title }), " : ", item.value] })); } }), _jsx(List, { size: "small", bordered: true, header: _jsx(Text, { strong: true, children: t("transactionManagement.details.playerInfoHeader") }), dataSource: playerInfo, renderItem: function (item) { return (_jsxs(List.Item, { children: [_jsx(Text, { strong: true, children: item.title }), " : ", item.value] })); } })] }));
};
export default TransactionDetails;
