import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteIcon, EditIcon } from "@/components/Icons";
import ArrowChevronIcon from "@/components/Icons/ArrowChevronIcon";
import StickyDrawer from "@/components/StickyDrawer";
import { Tabs } from "@atom/ui-kit";
import { Space, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EditTableManagement from "../../edit";
import { TableMoreInfoTabKeys, TableMoreInfoTabs } from "./MoreInfoDrawer.constants";
import { Container } from "./MoreInfoDrawer.style";
import TableDetail from "./_components/TableDetail";
import TableRounds from "./_components/TableRounds";
var MoreInfoDrawer = function (_a) {
    var trigger = _a.trigger, data = _a.data, activeTab = _a.activeTab;
    var _b = useState(activeTab || TableMoreInfoTabKeys.TableDetails), selectedTab = _b[0], setSelectedTab = _b[1];
    var _c = useState(false), drawerVisible = _c[0], setDrawerVisible = _c[1];
    var t = useTranslation().t;
    console.log(drawerVisible, "drawerVisible", data);
    return (_jsxs(StickyDrawer, { trigger: trigger, width: "70%", open: drawerVisible, onClose: function () { return setDrawerVisible(false); }, onOpen: function () { return setDrawerVisible(true); }, children: [_jsx(Container, { children: _jsx(Tabs, { activeKey: selectedTab, defaultActiveKey: selectedTab, items: TableMoreInfoTabs(t), onChange: function (value) {
                        setSelectedTab(value);
                    }, tabBarExtraContent: {
                        left: _jsx(ArrowChevronIcon, { style: { cursor: "pointer" }, onClick: function () { return setDrawerVisible(false); } }),
                        right: (_jsxs(Space, { children: [_jsx(Tooltip, { title: t("root.edit_button"), children: _jsx(EditIcon, { onClick: function () { return setSelectedTab(TableMoreInfoTabKeys.Edit); }, style: {
                                            cursor: "pointer",
                                        } }) }), _jsx(Tooltip, { title: t("root.delete_button"), children: _jsx(DeleteIcon, { onClick: function () { return setSelectedTab(TableMoreInfoTabKeys.TableDetails); }, style: {
                                            cursor: "pointer",
                                        } }) })] })),
                    } }) }), selectedTab === TableMoreInfoTabKeys.TableDetails && _jsx(TableDetail, { recordData: data, t: t }), selectedTab === TableMoreInfoTabKeys.TableRounds && _jsx(TableRounds, { recordData: data, t: t }), selectedTab === TableMoreInfoTabKeys.Edit && _jsx(EditTableManagement, { tableId: data.id, data: data })] }));
};
export default MoreInfoDrawer;
