export const transactionManagement = {
  transactionManagement: {
    tabs: {
      game: "Game Transactions",
      table: "Table Transactions",
      player: "Player Transactions",
      tenant: "Tenant Transactions",
    },
    columns: {
      username: "Username",
      tableStatus: "Table Status",
      playerCards: "Player Cards",
      otherPlayerCards: "Other Players",
      communityCards: "Community Cards",
      type: "Action Type",
      amount: "Amount",
      phaseTitle: "Game Phase",
      timestamp: "Timestamp",
      isBetHappened: "Bet Status",
      betsOpen: "Bet Open",
      betsClosed: "Bet Closed",
      winner: "Winner",
      noWinnerYet: "No winner yet",
      noCards: "No Cards",
      detail: "Game Details",
      userAction: {
        PLAYER_FOLD: "FOLD",
        PLAYER_CALL: "CALL",
        PLAYER_SIT: "SIT",
        PLAYER_CHECK: "CHECK",
        PLAYER_BET: "BET",
        BIG_BLINDS_POSTED: "BIG BLINDS POSTED",
      },
      phase: {
        TURN: "TURN",
        RIVER: "RIVER",
        FLOP: "FLOP",
        PRE_FLOP: "PREFLOP",
        INIT: "INIT",
        SHOWDOWN: "SHOWDOWN",
        DEAL: "DEAL",
      },
    },
    filters: {
      lobbyId: "Lobby",
      lobbyId_placeholder: "Enter Lobby",
      lobbyId_required: "Please enter Lobby",
      gameType: "Game Type",
      gameType_placeholder: "Enter Game Type",
      gameType_required: "Please enter Game Type",
      tableName: "Table Name",
      tableName_placeholder: "Enter Table Name",
      tableName_required: "Please enter Table Name",
      username: "Player Name",
      username_placeholder: "Search Player Name",
      username_required: "Please enter Player Name",
      dateRange: "Start Time- End Time",
      dateRange_placeholder: "Enter Start Time- End Time",
      dateRange_required: "Please enter Start Time- End Time",
      gameId: "Round ID",
      gameId_placeholder: "Enter Round ID",
      gameId_required: "Please enter Round ID",
      tableName_disabled: "Please select a Lobby and Game Type first",
    },
    details: {
      name: "Game Name",
      generalGameInfoHeader: "General Game Information",
      gameStateHeader: "Game State",
      playerInfoHeader: "Player Information",
      lobbyId: "Lobby ID",
      tableId: "Table ID",
      roundId: "Round ID",
      gameId: "Game ID",
      type: "Type",
      limitType: "Limit Type",
      totalSeats: "Total Seats",
      occupiedSeats: "Occupied Seats",
      minPlayers: "Minimum Players",
      maxBuyIn: "Maximum Buy-In",
      minBuyIn: "Minimum Buy-In",
      phase: "Phase",
      pot: "Pot",
      blinds: "Blinds",
      lastAction: "Last Action",
      lastActionAmount: "Last Action Amount",
      currentPlayer: "Current Player",
      timeoutDefaultAction: "Timeout Default Action",
      serviceFeePercent: "Service Fee (%)",
      tipPot: "Tip Pot",
      communityCards: "Community Cards",
      chips: "Chips",
      balance: "Balance",
      seatIndex: "Seat Index",
      betAmount: "Bet Amount",
      cards: "Cards",
      noCards: "No Cards",
      winner: "Winner",
      ante: "Ante",
      inProgress: "Game In Progress",
      inProgressTrue: "Yes",
      inProgressFalse: "No",
      isActive: "Table Active",
      isActiveTrue: "Active",
      isActiveFalse: "Inactive",
      isBetHappened: "Bet Happened",
      isBetHappenedTrue: "Yes",
      isBetHappenedFalse: "No",
      isPasswordProtected: "Password Protected",
      isPasswordProtectedTrue: "Yes",
      isPasswordProtectedFalse: "No",
      lastRaiseAmount: "Last Raise Amount",
    },
  },
};
