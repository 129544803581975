var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LocalStorageKeys } from "@/constants/localStorage";
import { Drawer } from "antd";
import React from "react";
import { TriggerContent } from "./StickyDrawer.style";
var StickyDrawer = React.forwardRef(function (_a, ref) {
    var title = _a.title, trigger = _a.trigger, children = _a.children, onOpen = _a.onOpen, onClose = _a.onClose, props = __rest(_a, ["title", "trigger", "children", "onOpen", "onClose"]);
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleOpen = function () {
        setOpen(true);
        onOpen === null || onOpen === void 0 ? void 0 : onOpen();
    };
    var handleClose = function (e) {
        setOpen(false);
        onClose === null || onClose === void 0 ? void 0 : onClose(e);
    };
    var sidebarCollapsed = localStorage.getItem(LocalStorageKeys.SIDEBAR_COLLAPSED) === "true";
    return (_jsxs(_Fragment, { children: [_jsx(TriggerContent, { onClick: handleOpen, children: trigger }), _jsx(Drawer, __assign({ destroyOnClose: true, rootClassName: "atom-sticky-drawer", maskClassName: sidebarCollapsed ? "atom-sticky-drawer-mask atom-sticky-drawer-mask-expand" : "atom-sticky-drawer-mask", className: "atom-sticky-drawer-content", title: title, placement: "right", closable: false, onClose: handleClose, open: open }, props, { children: children }))] }));
});
export default StickyDrawer;
