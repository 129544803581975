export var ActionTypeTagColors = {
    PLAYER_SIT: "green",
    PLAYER_CHECK: "blue",
    PLAYER_BET: "orange",
    PLAYER_CALL: "purple",
    PLAYER_FOLD: "red",
    PLAYER_LOST: "red",
};
export var TransactionTabKeys = {
    Player: "player",
    Game: "game",
    Table: "table",
    Tenant: "tenant",
};
export var TransactionTabs = function (t) { return [
    {
        label: t("transactionManagement.tabs.table"),
        key: TransactionTabKeys.Table,
    },
    {
        label: t("transactionManagement.tabs.game"),
        key: TransactionTabKeys.Game,
    },
    {
        label: t("transactionManagement.tabs.player"),
        key: TransactionTabKeys.Player,
    },
    {
        label: t("transactionManagement.tabs.tenant"),
        key: TransactionTabKeys.Tenant,
    },
]; };
