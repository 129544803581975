import { jsx as _jsx } from "react/jsx-runtime";
import CardPopover from "@/pages/transaction-management/_components/CardPopover";
import { getCardImageURL } from "@/pages/transaction-management/TransactionManagement.helpers";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
var CommunityCards = function (_a) {
    var communityCards = _a.communityCards;
    var t = useTranslation().t;
    return (_jsx(Card, { title: t("gameManagement.game.communityCards"), bordered: true, children: _jsx("div", { style: { display: "flex", gap: "8px" }, children: communityCards === null || communityCards === void 0 ? void 0 : communityCards.map(function (card, index) { return (_jsx(CardPopover, { cardUrl: getCardImageURL(card) })); }) }) }));
};
export default CommunityCards;
