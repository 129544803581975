var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var ArrowChevronIcon = function (props) {
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "38", height: "38", viewBox: "0 0 38 38", fill: "none" }, props, { style: {
            transform: props.rotate ? "rotate(".concat(props.rotate, "deg)") : "rotate(0deg)",
        }, children: _jsx("path", { d: "M23.692 7.9165L12.667 18.9415L23.692 29.9665", stroke: "#A0A3BD", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" }) })));
};
export default ArrowChevronIcon;
