"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableControllerApiFactory } from "@/apis/table-management";
import GoBack from "@/components/GoBack";
import { useApi } from "@/hooks/useApi";
import { Card, message } from "@atom/ui-kit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GameConfigForm from "./_components/GameConfigForm";
import TableAddFormSteps from "./_components/TableAddFormSteps";
import TableFlagsForm from "./_components/TableFlagsForm";
import TableForm from "./_components/TableForm";
var AddTableManagement = function () {
    var t = useTranslation().t;
    var router = useNavigate();
    var _a = useState({
        step: 0,
        formValues: {
            tableFormValues: null,
            gameConfigFormValues: null,
            tableFlagsFormValues: null,
        },
    }), state = _a[0], setState = _a[1];
    var _b = useApi({
        asyncFunction: TableControllerApiFactory().tablesPost,
        successCallback: function (response) {
            setState({
                step: 3,
                formValues: {
                    tableFormValues: null,
                    gameConfigFormValues: null,
                    tableFlagsFormValues: null,
                },
            });
            message.success(t("tableManagement.add.success"));
            router("/table-management");
        },
        errorCallback: function (error) {
            message.error(t("tableManagement.add.error"));
        },
    }), callAddTableManagement = _b.call, loading = _b.loading;
    var handleFinish = function (values) {
        var _a;
        var tableFlags = {
            isAutoCreateGame: values.isAutoCreateGame,
            highlighted: values.highlighted,
        };
        delete values.isAutoCreateGame;
        delete values.highlighted;
        callAddTableManagement({
            tablesPostRequest: __assign(__assign(__assign({}, state.formValues.tableFormValues), tableFlags), { gameConfig: __assign(__assign({}, (_a = state === null || state === void 0 ? void 0 : state.formValues) === null || _a === void 0 ? void 0 : _a.gameConfigFormValues), values) }),
        });
        setState(__assign(__assign({}, state), { formValues: __assign(__assign({}, state.formValues), { tableFlagsFormValues: values }) }));
    };
    var handleTableFormFinish = function (values) {
        setState({
            step: 1,
            formValues: {
                tableFormValues: values,
                gameConfigFormValues: null,
                tableFlagsFormValues: null,
            },
        });
    };
    var handleGameConfigFormFinish = function (values) {
        var _a;
        setState({
            step: 2,
            formValues: __assign(__assign({}, state.formValues), { gameConfigFormValues: values, tableFormValues: (_a = state.formValues) === null || _a === void 0 ? void 0 : _a.tableFormValues }),
        });
    };
    var handleChangeStep = function (current) {
        setState(__assign(__assign({}, state), { step: current }));
    };
    return (_jsxs(Card, { style: { padding: "20px 0" }, children: [_jsx(GoBack, { title: t("tableManagement.add.title") }), _jsx(TableAddFormSteps, { t: t, current: state.step, onChange: handleChangeStep }), state.step === 0 && _jsx(TableForm, { t: t, onFinish: handleTableFormFinish, formValues: state.formValues.tableFormValues }), state.step === 1 && _jsx(GameConfigForm, { t: t, onFinish: handleGameConfigFormFinish, formValues: state.formValues.gameConfigFormValues, onGoBack: function () { return handleChangeStep(0); } }), state.step === 2 && _jsx(TableFlagsForm, { t: t, onFinish: handleFinish, loading: loading, formValues: state.formValues.tableFlagsFormValues, onGoBack: function () { return handleChangeStep(1); } })] }));
};
export default AddTableManagement;
