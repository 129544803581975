/* tslint:disable */
/* eslint-disable */
/**
 * PokerWise Table Management
 * API for managing PokerWise tables.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export var GameConfigLimitTypeEnum = {
    Nolimit: 'nolimit',
    Potlimit: 'potlimit',
    Fixedlimit: 'fixedlimit',
    Spreadlimit: 'spreadlimit'
};
export var GameConfigTimeoutDefaultActionEnum = {
    Fold: 'Fold',
    Call: 'Call'
};
