import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TenantSelect from "@/components/Selects/TenantSelect";
import { LocalStorageKeys } from "@/constants/localStorage";
import { Button, Form, FormItem } from "@atom/ui-kit";
import { useTranslation } from "react-i18next";
var TenantForm = function (_a) {
    var handleTenantSelection = _a.handleTenantSelection, loading = _a.loading;
    var t = useTranslation().t;
    return (_jsxs(Form, { onFinish: handleTenantSelection, initialValues: {
            tenant: localStorage.getItem(LocalStorageKeys.TENANT) || "wisegaming",
            is_raw_password: true,
        }, children: [_jsx(FormItem, { name: "tenant", rules: [
                    {
                        required: true,
                        type: "string",
                        message: t("signIn.tenantForm.tenantErrorMessage"),
                    },
                ], children: _jsx(TenantSelect, { placeholder: t("signIn.tenantForm.tenantPlaceholder") }) }), _jsx(FormItem, { children: _jsx(Button, { block: true, buttonType: "primary", loading: loading, htmlType: "submit", children: t("signIn.tenantForm.nextButton") }) })] }));
};
export default TenantForm;
